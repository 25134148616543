import { ContractDto } from '@client/shared/api';
import { formatPercentage } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';
import { Column } from './CalculateContainer';
import { OptionalColumn } from './CalculateSectionHeader';
import { CalculateElementRowInformationColumn } from './CalculateElementRowInformationColumn';
import { CalculateInformationColumnContent } from './CalculateGroupElementInformationColumn';
import { CalculateColumns } from './CalculateColumns';
import React from 'react';
import { TimeLineView } from './Timeline';
import { FormattedCurrency } from '@client/project/shared';
import { CalculateElementRowFinanceColumn } from './CalculateElementRowFinanceColumn';
import { CalculateSelectableColumn } from './CalculateSelectableColumn';
import cn from 'classnames';

interface CostContractElementProps {
  contract: ContractDto;
  level: number;
  optionalColumn: OptionalColumn;
  obligoColumn: OptionalColumn;
  index: number;
  budget: number;
  restBudget: boolean;
  top?: number;
  onClick?: () => void;
}

export const CostContractElement = ({
  contract,
  level,
  optionalColumn,
  obligoColumn,
  index,
  budget,
  restBudget,
  top = 0,
  onClick
}: CostContractElementProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={cn(
        'contract-element w-full flex group absolute left-0 bg-transparent',
        {
          'hover:bg-neutral-100 transition-colors duration-200 cursor-pointer': onClick
        }
      )}
      data-contract={contract.code}
      data-cost-element={contract.costElementId}
      style={{ top: top }}
      onClick={onClick}
      title={`${contract.code} - ${contract.name}`}
    >
      <CalculateColumns
        view={TimeLineView.FINANCE}
        informationColumnContent={
          <CalculateElementRowInformationColumn
            contentType="row"
            level={level}
            view={TimeLineView.FINANCE}
            leftColumn={
              <CalculateInformationColumnContent
                showCode={!!(contract?.code)}
                code={contract.code}
                level={level}
                contentType="contract"
                restBudget={restBudget}
                index={index}
                showExpanded
              >
                <div className="truncate flex flex-row items-center">
                  <span className="overflow-x-hidden text-ellipsis flex flex-row font-bold">
                    <div className="font-normal mr-1">{t('projectCalculate.contract')}</div>
                    <div className="mr-1 truncate">{contract.name}</div>
                    {(contract.budgetValue !== 0 || contract.budgetValue !== 0) &&
                      budget !== 0 && (
                        <div className="text-xxs opacity-70">
                          {formatPercentage(
                            ((contract.budgetValue || contract.budgetValue) ?? 0) / budget,
                            { maxDigits: 0 }
                          )}
                        </div>
                      )}
                  </span>
                </div>
              </CalculateInformationColumnContent>
            }
            rightColumn={<>right column</>}
          />
        }
        currentColumnContent={
          <CalculateElementRowFinanceColumn
            level={level}
            columnType={Column.CURRENT}
            view={TimeLineView.FINANCE}
          >
            {!!contract.budgetValue && <FormattedCurrency amount={contract.budgetValue} />}
          </CalculateElementRowFinanceColumn>
        }
        obligoColumnContent={
          <CalculateSelectableColumn
            column={obligoColumn}
            level={level}
            columnType={Column.OBLIGO}
            view={TimeLineView.FINANCE}
            values={contract}
            type="contract"
          />
        }
        optionalColumnContent={
          <CalculateSelectableColumn
            column={optionalColumn}
            level={level}
            columnType={Column.OPTIONAL_COLUMN}
            view={TimeLineView.FINANCE}
            values={contract}
            type="contract"
          />
        }
      />
    </div>
  );
};
