import classNames from 'classnames';
import React, { PropsWithChildren, useContext, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { settings, useFeatureFlags, useUi } from '@client/shared/store';
import {
  ProtectedNavLink,
  ProtectedNavLinkWithRef,
  RouteConfig,
  RouteNavType,
  ROUTES_CONFIG
} from '@client/shared/permissions';
import {
  Button,
  DownloadToExcelCustomIcon,
  DownloadToPdfCustomIcon,
  DownloadToPngCustomIcon,
  LoadingIndicator,
  useComponentDimensions,
} from '@client/shared/toolkit';
import { Menu, MenuButton, MenuItem, MenuItems, Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { usePopper } from 'react-popper';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { ReportingContext } from './ReportingContextProvider';
import { useLoadedProjectId } from '@client/project/store';
import { useLocation } from 'react-router-dom';
import { ReportReadModel, useApiGetSystemReportsQuery, useApiGetTenantReportsQuery } from '@client/shared/api';
import { LazyReportExportButtons } from '@client/project/shared';

interface ReportingTabsProps {
  className?: string;
}

export const ReportingTabs = ({className}: ReportingTabsProps) => {
  const {t} = useTranslation();
  const {fakeUi: showFakeUi} = useFeatureFlags();
  const ui = useUi();
  const user = ui.appUser;
  const tenant = user.tenant;

  const location = useLocation();
  const {onDownload, calculationModelId, datasourceParameters, exportFilterConfig, exportVariables, exportCustomComponents} = useContext(ReportingContext);

  const {data: tenantReportsData, isFetching: isFetchingTenantReports} = useApiGetTenantReportsQuery({
    type: 'Generic'
  });
  const {data: systemReportsData, isFetching: isFetchingSystemReports} = useApiGetSystemReportsQuery({
    type: 'Generic'
  });

  const projectId = useLoadedProjectId();
  const downloadExcelRoutes = useMemo(() => {
    return [
      ROUTES_CONFIG.REPORTING_COMPARISON.path.replace(':id', projectId ?? ''),
      ROUTES_CONFIG.REPORTING_BUDGET.path.replace(':id', projectId ?? ''),
      ROUTES_CONFIG.REPORTING_FORECAST.path.replace(':id', projectId ?? ''),
    ];
  }, [projectId]);
  const downloadPdfRoutes = useMemo(() => {
    return [
      ...downloadExcelRoutes,
      ROUTES_CONFIG.REPORTING_BASE.path.replace(':id', projectId ?? ''),
      ROUTES_CONFIG.REPORTING_COST_BREAKDOWN_REPORT.path.replace(':id', projectId ?? ''),
      ROUTES_CONFIG.REPORTING_BMW_COST_WATERFALL.path.replace(':id', projectId ?? ''),
      ROUTES_CONFIG.REPORTING_BMW_SAZ.path.replace(':id', projectId ?? '')
    ];
  }, [downloadExcelRoutes, projectId]);

  const exportRoutes = useMemo(() => {
    const routes = [
      ROUTES_CONFIG.REPORTING_DETAILS.path.replace(':id', projectId ?? ''),
      ROUTES_CONFIG.REPORTING_VARIATION.path.replace(':id', projectId ?? ''),
    ];
    return routes;
  }, [projectId]);

  const showExcelDownload = useMemo(() => {
    return downloadExcelRoutes.includes(location.pathname);
  }, [downloadExcelRoutes, location.pathname]);

  const showPdfDownload = useMemo(() => {
    return downloadPdfRoutes.includes(location.pathname);
  }, [downloadPdfRoutes, location.pathname]);

  const showExportButtons = useMemo(() => {
    return exportRoutes.includes(location.pathname);
  }, [exportRoutes, location.pathname]);

  const exportReportId = useMemo(() => {
    if (location.pathname === ROUTES_CONFIG.REPORTING_DETAILS.path.replace(':id', projectId ?? '')) {
      return 'bb82f4f0-d23d-464e-885e-5cfe1956176b';
    } else if (location.pathname === ROUTES_CONFIG.REPORTING_VARIATION.path.replace(':id', projectId ?? '')) {
      return 'c214c761-44f0-40d7-9b52-c8c3c8ceb93f';
    }
    return null;
  }, [location.pathname, projectId]);

  const exportReportButtons: ('png' | 'pdf' | 'xlxs')[] = useMemo(() => {
    if (location.pathname === ROUTES_CONFIG.REPORTING_DETAILS.path.replace(':id', projectId ?? '') || location.pathname === ROUTES_CONFIG.REPORTING_VARIATION.path.replace(':id', projectId ?? '')) {
      return ['png', 'pdf', 'xlxs'];
    }
    return [];
  }, [location.pathname, projectId]);

  const reportingTabs: RouteNavType[] = useMemo(() => {
    const tabs: RouteNavType[] = [
      {
        routeConfig: ROUTES_CONFIG.REPORTING_BASE,
        label: t('reporting.reportBaseTitle'),
      },
      {
        routeConfig: ROUTES_CONFIG.REPORTING_COMPARISON,
        label: t('reporting.reportComparison.title'),
      },
      {
        routeConfig: ROUTES_CONFIG.REPORTING_BUDGET,
        label: t('reporting.reportBudget.title'),
      },
      {
        routeConfig: ROUTES_CONFIG.REPORTING_DETAILS,
        label: t('reporting.reportDetails.title'),
      },
      {
        routeConfig: ROUTES_CONFIG.REPORTING_VARIATION,
        label: t('reporting.reportVariation.title'),
      },
      {
        routeConfig: ROUTES_CONFIG.REPORTING_FORECAST,
        label: t('reporting.reportForecast.title'),
      },
    ];

    if (showFakeUi) {
      tabs.push({
        routeConfig: ROUTES_CONFIG.REPORTING_FORECAST_STEPS,
        label: t('reporting.reportForecastSteps.title'),
      });
    }

    tabs.push({
      routeConfig: ROUTES_CONFIG.REPORTING_COST_BREAKDOWN_REPORT,
      label: t('reporting.reportCostBreakdown.title'),
    });

    return tabs;
  }, [t, showFakeUi]);

  const tenantReports: RouteNavType[] = useMemo(() => {
    const tabs: RouteNavType[] = [];

    if (
      !!user.permissions?.licenses.find((license) => license.feature === 'BMW_REPORTING') &&
      (user.tenant?.tenantId === '8efa5e39-d89d-42b9-a00b-9718000d0fbb' ||
        user.tenant?.tenantId === '7507b90d-27cc-48f6-bca7-ed3589ac7f70' ||
        settings.mainDomain === 'probis.test' ||
        settings.mainDomain === 'probis.dev')
    ) {
      tabs.push(
        {
          routeConfig: ROUTES_CONFIG.REPORTING_BMW_COST_WATERFALL,
          label: 'Cost Waterfall',
        },
        {
          routeConfig: ROUTES_CONFIG.REPORTING_BMW_SAZ,
          label: 'SAZ',
        },
        {
          routeConfig: ROUTES_CONFIG.REPORTING_BMW_RISKS_OPS,
          label: 'Risks & Ops',
        },
        {
          routeConfig: ROUTES_CONFIG.REPORTING_BMW_CHANGE_MGMT,
          label: 'Change Mgmt',
        },
      );
    }

    /* if (
      user.tenant?.tenantId === '3239f945-4fce-4ebb-931f-89700f4c7631' ||
      settings.mainDomain === 'probis.test' ||
      settings.mainDomain === 'probis.dev'
    ) {
      tabs.push({
        routeConfig: ROUTES_CONFIG.REPORTING_CONTROLLING_REPORT,
        label: 'AXA Controlling Report',
      });
    } */

    if (
      settings.mainDomain === 'probis.test' ||
      settings.mainDomain === 'probis.dev' ||
      (settings.mainDomain === 'probis.qa' && user.tenant?.tenantId === '311f17d5-bf27-4850-9b94-9bbca03b0207')
    ) {
      tabs.push({
        routeConfig: ROUTES_CONFIG.REPORTING_CARBON_EMISSION_CHART,
        label: 'LocalLaw97',
      });
    }

    return tabs;
  }, [user.permissions?.licenses, user.tenant?.tenantId]);

  const genericReports: ReportReadModel[] = useMemo(() => {
    let loadedReports: ReportReadModel[] = [];
    if (systemReportsData) {
      loadedReports = systemReportsData;
    }
    if (tenantReportsData) {
      loadedReports = [...loadedReports, ...tenantReportsData];
    }
    return loadedReports;
  }, [tenantReportsData, systemReportsData]);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const dimensionsWrapper = useComponentDimensions(wrapperRef);
  const downloadButtonsRef = useRef<HTMLDivElement>(null);

  const [isLoading, setIsLoading] = useState(false);

  const [targetElement, setTargetElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const {styles, attributes} = usePopper(targetElement, popperElement, {
    placement: 'bottom',
  });

  const [tenantReportMenuTargetElement, setTenantReportMenuTargetElement] = useState<HTMLElement | null>(null);
  const [tenantReportMenuPopperElement, setTenantReportMenuPopperElement] = useState<HTMLElement | null>(null);
  const {styles: tenantReportMenuStyles, attributes: tenantReportMenuAttributes} = usePopper(
    tenantReportMenuTargetElement,
    tenantReportMenuPopperElement,
    {
      placement: 'bottom-start',
      modifiers: [
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['bottom-end', 'top-start', 'top-end'],
            rootBoundary: 'viewport',
          },
        },
        {name: 'offset', options: {offset: [0, 8]}},
      ],
    },
  );

  const maxTabItems = useMemo(() => {
    if (dimensionsWrapper?.width && wrapperRef.current && window.innerWidth >= 1280) {
      const maxWidth =
        dimensionsWrapper.width -
        16 -
        (downloadButtonsRef.current?.clientWidth ?? 0) -
        (tenantReportMenuTargetElement?.clientWidth ?? 0);
      let tabsWidth = 0;
      let max = 0;
      const renderedTabs = wrapperRef.current.querySelectorAll('.reporting-tab');
      renderedTabs.forEach((tab) => {
        if (tabsWidth + tab.clientWidth + 8 <= maxWidth) {
          tabsWidth = tabsWidth + tab.clientWidth + 8;
          max++;
        }
      });
      return max;
    }
    return reportingTabs.length;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dimensionsWrapper.width,
    reportingTabs.length,
    wrapperRef,
    downloadButtonsRef.current?.clientWidth,
    tenantReportMenuTargetElement,
  ]);

  const visibleTabItems = useMemo(() => {
    const items: RouteNavType[] = [...reportingTabs];
    return items.slice(0, maxTabItems);
  }, [reportingTabs, maxTabItems]);

  const hiddenTabItems = useMemo(() => {
    const items: RouteNavType[] = [...reportingTabs];
    return items.slice(maxTabItems);
  }, [reportingTabs, maxTabItems]);

  const reportName = useMemo(() => {
    if (projectId && ROUTES_CONFIG.REPORTING_COMPARISON.path.replace(':id', projectId) === location.pathname) {
      return 'comparison-report';
    }
    if (projectId && ROUTES_CONFIG.REPORTING_BUDGET.path.replace(':id', projectId) === location.pathname) {
      return 'budget-report';
    }
    if (projectId && ROUTES_CONFIG.REPORTING_FORECAST.path.replace(':id', projectId) === location.pathname) {
      return 'forecast-report';
    }
    if (projectId && ROUTES_CONFIG.REPORTING_BASE.path.replace(':id', projectId) === location.pathname) {
      return 'base-report';
    }
    if (projectId && ROUTES_CONFIG.REPORTING_DETAILS.path.replace(':id', projectId) === location.pathname) {
      return 'details-report';
    }
    if (projectId && ROUTES_CONFIG.REPORTING_VARIATION.path.replace(':id', projectId) === location.pathname) {
      return 'variation-report';
    }
    return 'report';
  }, [projectId, location.pathname]);

  const pdfExportWidth = useMemo(() => {
    if (
      projectId &&
      (ROUTES_CONFIG.REPORTING_COST_BREAKDOWN_REPORT.path.replace(':id', projectId) === location.pathname || ROUTES_CONFIG.REPORTING_VARIATION.path.replace(':id', projectId) === location.pathname)
    ) {
      return 2480;
    }

    return 1440;
  }, [projectId, location.pathname]);

  const onDownloadImage = (pdf: boolean, chart?: boolean) => {
    setIsLoading(true);

    const chartToExport = document.getElementById('export-chart');
    if (chartToExport) {
      try {
        html2canvas(chartToExport, {
          scale: 2
        }).then((canvas) => {
          const height = chartToExport.clientHeight ? chartToExport.clientHeight : 0;
          const width = chartToExport.clientWidth;
          const imgData = canvas.toDataURL('image/png');
          // PDF download
          if (pdf) {
            const pdf = new jsPDF({
              orientation: 'landscape',
              unit: 'pt',
            });
            pdf.internal.pageSize.height = height;
            pdf.internal.pageSize.width = width;
            pdf.addImage(imgData, 'image/png', 0, 0, width, height);
            pdf.save(`${reportName}-${Date.now()}.pdf`);
          } else {
            // PNG Download
            const a = document.createElement('a');
            a.href = imgData;
            a.download = `${reportName}-${Date.now()}.png`;
            a.click();
          }
        }).then(() => {
          setIsLoading(false);
        });
      } catch (e) {
        console.error(e);
        setIsLoading(false);
      }
    } else {
      // fixes text moved down https://github.com/niklasvh/html2canvas/issues/2775
      const style = document.createElement('style');
      document.head.appendChild(style);
      style.sheet?.insertRule('body > div:last-child img { display: inline-block; }');

      const html = document.documentElement.outerHTML;
      // prevent uncaught errors
      /* const cloneElement: Element = document.documentElement.cloneNode(true) as Element;
      if (cloneElement.firstChild?.nodeName === 'HEAD') {
        cloneElement.removeChild(cloneElement.firstChild);
      }
      cloneElement.querySelector('vite-plugin-checker-error-overlay')?.remove();
      cloneElement.querySelector('div[id="userpilotContent"]')?.remove(); */

      const iframe = document.createElement('iframe');
      iframe.style.width = `${pdfExportWidth}px`;
      iframe.style.height = '500px';
      iframe.style.opacity = '0';

      document.body.appendChild(iframe);
      iframe.srcdoc = html; // cloneElement.outerHTML;

      iframe.addEventListener('load', () => {
        if (iframe.contentWindow?.document.body) {
          const body = iframe.contentWindow?.document.body;
          body.classList.add('pdf-export');
          const pdfContent = iframe.contentWindow.document.getElementById('pdf-content');
          if (pdfContent) {
            iframe.style.height = `${pdfContent.scrollHeight + 50}px`;
            pdfContent.style.background = 'white';
            pdfContent.style.paddingTop = '20px';
          }
          // puts everything on multiple pages
          /* html2PDF(iframe.contentWindow?.document.body, {
            jsPDF: {
              format: 'a4',
              orientation: 'landscape',
            },
            imageType: 'image/jpeg',
          }).then(() => {
            document.body.removeChild(iframe);
          }); */
          // everything in one image and one page
          try {
            html2canvas(iframe.contentWindow?.document.body, {
              scale: 2,
            })
              .then((canvas) => {
                const height = iframe.clientHeight ? iframe.clientHeight : 0;
                const width = pdfExportWidth;

                const imgData = canvas.toDataURL('image/png');
                // PDF download
                if (pdf) {
                  const pdf = new jsPDF({
                    orientation: 'landscape',
                    unit: 'pt',
                  });
                  pdf.internal.pageSize.height = height;
                  pdf.internal.pageSize.width = width;
                  pdf.addImage(imgData, 'image/png', 0, 0, width, height);
                  pdf.save(`${reportName}-${Date.now()}.pdf`);
                } else {
                  // PNG Download
                  const a = document.createElement('a');
                  a.href = imgData;
                  a.download = `${reportName}-${Date.now()}.png`;
                  a.click();
                }
              })
              .then(() => {
                style.remove();
                document.body.removeChild(iframe);
                setIsLoading(false);
              });
          } catch (e) {
            console.error(e);
            setIsLoading(false);
          }
        }
      });
    }
  };

  return (
    <div
      className={classNames(
        'w-full max-w-full h-full gap-2 flex flex-nowrap items-center justify-end pdf-export-hidden',
        className,
      )}
      ref={wrapperRef}
    >
      {isLoading && <LoadingIndicator mode="overlay-window" text={t('reporting.exportingLoadingIndicator')}/>}
      <div
        className={classNames('flex-shrink flex gap-2 flex-wrap xl:flex-nowrap items-center', {
          'border-r pr-4': showExcelDownload || showPdfDownload || showExportButtons,
        })}
      >
        {reportingTabs.length > 0 && (
          <ul className="flex flex-wrap xl:flex-nowrap justify-center xl:justify-end gap-2 w-full">
            {visibleTabItems.map((item, i) => (
              <li key={`reporting-tab-${i}`} className="reporting-tab">
                <ReportingTab routeConfig={item.routeConfig} projectId={projectId}>
                  {item.label}
                </ReportingTab>
              </li>
            ))}
            {tenantReports.length > 0 && (
              <li>
                <Menu as="div" ref={setTenantReportMenuTargetElement} className={className}>
                  {({open, close}) => (
                    <>
                      <MenuButton className="cursor-pointer" as="div" onClick={undefined}>
                        <Button
                          variant="custom"
                          className="flex-grow-0 flex flex-row items-center justify-center w-auto rounded-full px-6 h-8 text-[15px] text-slate-500 font-bold hover:bg-slate-500 hover:text-white cursor-pointer transition-colors whitespace-nowrap"
                        >
                          <span className="font-bold">
                            {tenant?.name
                              ? t('reporting.tenantReports', {tenant: tenant?.name})
                              : t('reporting.customReports')}
                          </span>
                        </Button>
                      </MenuButton>
                      {open && (
                        <MenuItems
                          portal
                          as="div"
                          ref={setTenantReportMenuPopperElement}
                          className="w-48 font-light bg-white divide-y divide-gray-100 rounded-md shadow-lg overflow-hidden z-30"
                          style={{...tenantReportMenuStyles.popper}}
                          {...tenantReportMenuAttributes.popper}
                        >
                          {tenantReports.map((item, index) => (
                            <MenuItem
                              key={`tenant-report-${item.label}-${index}`}
                              as={ProtectedNavLinkWithRef}
                              path={item.routeConfig.name}
                              routeConfig={item.routeConfig}
                              className={classNames(
                                'flex px-4 py-1 hover:bg-blue-200 text-[15px] text-slate-500 font-bold w-full h-full',
                                {
                                  'border-t border-gray-200': index >= 1,
                                },
                              )}
                              projectId={projectId}
                              onClick={close}
                            >
                              {item.label}
                            </MenuItem>
                          ))}
                          {isFetchingTenantReports || isFetchingSystemReports ? (
                            <LoadingIndicator mode="inline"/>
                          ) : (
                            <>
                              {genericReports.length > 0 &&
                                genericReports.map((report, index) => (
                                  <MenuItem
                                    key={`tenant-specific-report-${index}`}
                                    as={ProtectedNavLinkWithRef}
                                    path={ROUTES_CONFIG.REPORTING_REPORT_VIEW.path
                                      .replace(':id', projectId ?? '')
                                      .replace(':reportId', report.metaData.reportId ?? '')}
                                    routeConfig={ROUTES_CONFIG.REPORTING_REPORT_VIEW}
                                    className={classNames(
                                      'flex px-4 py-1 hover:bg-blue-200 text-[15px] text-slate-500 font-bold w-full h-full',
                                      {
                                        'border-t border-gray-200': index >= 1,
                                      },
                                    )}
                                    onClick={close}
                                    projectId={projectId}
                                  >
                                    {report.metaData.name}
                                  </MenuItem>
                                ))}
                            </>
                          )}
                        </MenuItems>
                      )}
                    </>
                  )}
                </Menu>
              </li>
            )}
          </ul>
        )}

        {hiddenTabItems.length > 0 && (
          <Popover>
            <div ref={setTargetElement}>
              <PopoverButton className="h-full flex items-center">
                <div className="px-2 flex gap-2 items-center w-full font-medium cursor-pointer">
                  <EllipsisVerticalIcon className="w-6"/>
                </div>
              </PopoverButton>
            </div>
            <PopoverPanel
              portal
              ref={setPopperElement}
              style={{...styles.popper}}
              {...attributes.popper}
              className="w-full mt-3 px-4 z-20 flex items-center outline-none min-h-16 bg-white shadow-lg border-t-2 py-3"
            >
              <div className={classNames('flex flex-wrap items-center w-full justify-center gap-2')}>
                {hiddenTabItems.map((item, j) => (
                  <ReportingTab
                    key={`reporting-tab-${item.label}-${j}`}
                    routeConfig={item.routeConfig}
                    projectId={projectId}
                  >
                    {item.label}
                  </ReportingTab>
                ))}
              </div>
            </PopoverPanel>
          </Popover>
        )}
      </div>
      {(showExcelDownload || showPdfDownload || showExportButtons) && (
        <div className="flex-none flex" ref={downloadButtonsRef}>
          {showPdfDownload && (
            <>
              <Button
                variant="custom"
                className="focus:outline-none active:outline-none flex flex-col items-center font-bold text-[11px] gap-1 text-slate-500 hover:text-slate-700 transition-colors duration-300"
                onClick={() => onDownloadImage(false)}
              >
                <DownloadToPngCustomIcon className="w-7"/>
                {t('reporting.reportDownloadToPng')}
              </Button>

              <Button
                variant="custom"
                className="focus:outline-none active:outline-none flex flex-col items-center font-bold text-[11px] gap-1 text-slate-500 hover:text-slate-700 transition-colors duration-300"
                onClick={() => onDownloadImage(true)}
              >
                <DownloadToPdfCustomIcon className="w-7"/>
                {t('reporting.reportDownloadToPDF')}
              </Button>
            </>
          )}
          {showExcelDownload && (
            <Button
              variant="custom"
              className="focus:outline-none active:outline-none flex flex-col items-center font-bold text-[11px] gap-1 text-slate-500 hover:text-slate-700 transition-colors duration-300"
              onClick={onDownload}
            >
              <DownloadToExcelCustomIcon className="w-7"/>
              {t('reporting.reportDownloadToExcel')}
            </Button>
          )}
          {showExportButtons && exportReportId && (
            <div className="flex gap-2 xl:gap-5 items-center h-10 justify-end mx-4">
              <LazyReportExportButtons
                reportId={exportReportId}
                type="export"
                buttons={exportReportButtons}
                calculationModelId={calculationModelId}
                datasourceParameters={datasourceParameters}
                filterConfig={exportFilterConfig}
                variables={exportVariables}
                customComponents={exportCustomComponents}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

interface ReportingTabProps extends PropsWithChildren {
  routeConfig: RouteConfig;
  className?: string;
  projectId?: string;
}

const ReportingTab = ({children, className, routeConfig, projectId}: ReportingTabProps) => {
  return (
    <ProtectedNavLink
      path={routeConfig.name}
      routeConfig={routeConfig}
      className="flex-grow-0 flex flex-row items-center justify-center w-auto rounded-full px-6 h-8 text-[15px] text-slate-500 font-bold hover:bg-slate-500 hover:text-white cursor-pointer transition-colors whitespace-nowrap"
      activeClassName="bg-slate-500 text-white"
      disabledClassName="flex-grow-0 flex flex-row items-center justify-center w-auto rounded-full px-6 h-8 text-[15px] text-slate-500 font-bold whitespace-nowrap cursor-not-allowed"
      projectId={projectId}
    >
      {children}
    </ProtectedNavLink>
  );
};
