import {
  ContextMenu,
  ContextMenuItem,
  FloatingActionButton,
  List,
  ListItemProps,
  LoadingIndicator,
  Modal,
  PencilIcon,
  SlideOver,
  ToggleSwitch,
  TrashIcon,
  EyeIcon,
  AddIcon,
  FilterOption,
  AddToClipboardIcon
} from '@client/shared/toolkit';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ArrowDownOnSquareIcon,
  Square2StackIcon
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { ContractListItem, ContractListItemSum, ContractListSortHeader } from './ContractListItem';
import { useLoadedProjectId, useLoadedVariantId, useToggledContractFilters } from '@client/project/store';
import {
  ShortCommitmentReadModel,
  ShortContractReadModel,
  useApiGetContractsQuery,
} from '@client/shared/api';
import {
  CommitmentDeleteModal,
  CommitmentView,
  ContractCopyModal,
  ContractDeleteModal,
  ContractViewType,
  ImportContractsSlideOver,
} from '.';
import { ProtectedRoute, ROUTES_CONFIG, useValidateProjectPermission } from '@client/shared/permissions';
import { CommitmentNewWizard } from './CommitmentNewWizard';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { ContractView } from './ContractView';
import { ContractNewWizard } from '@client/project/shared'
import { ContractEditSlideOver } from '@client/project/shared';

const searchForContract = (contracts: ShortContractReadModel[], searchValue: string, searchResults?: string[], toggledContractFilters?: FilterOption[]) => {
  const searchFor = searchValue.toLowerCase();

  return contracts.filter((contract) => {
    return (
      toggledContractFilters && toggledContractFilters.length > 0  ? searchResults && searchResults?.includes(contract.id) :
      (contract.code.toLowerCase().includes(searchFor) ||
      contract.name?.toLowerCase().includes(searchFor) ||
      contract.contractorName?.toLowerCase().includes(searchFor) ||
      contract.clientName?.toLowerCase().includes(searchFor)))
  });
};

export interface ContractContainerProps {
  searchValue?: string;
  searchResults?: string[];
}

export const ContractContainer = ({ searchValue = '', searchResults = [] }: ContractContainerProps) => {
  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const canWrite = useValidateProjectPermission(['CONTRACT_WRITE'], loadedProjectId ?? '');
  const canDelete = useValidateProjectPermission(['CONTRACT_DELETE'], loadedProjectId ?? '');

  const [isOpenContractDetailsSlideOver, setIsOpenContractDetailsSlideOver] = useState(false);
  const [isOpenEditContractSlideOver, setIsOpenEditContractSlideOver] = useState(false);
  const [isOpenImportContractsSlideOver, setIsOpenImportContractsSlideOver] = useState(false);
  const [isOpenNewContractModal, setIsOpenNewContractModal] = useState(false);
  const [isOpenNewCommitmentModal, setIsOpenNewCommitmentModal] = useState(false);
  const [isOpenContractDeleteModal, setIsOpenContractDeleteModal] = useState(false);
  const [isOpenContractCopyModal, setIsOpenContractCopyModal] = useState(false);
  const [contracts, setContracts] = useState<ShortContractReadModel[]>([]);
  const [allContracts, setAllContracts] = useState<ShortContractReadModel[]>([]);
  const [selectedContract, setSelectedContract] = useState<ShortContractReadModel | null>(null);
  const [isOpenCommitmentDeleteModal, setIsOpenCommitmentDeleteModal] = useState(false);
  // const [isOpenCommitmentCopyModal, setIsOpenCommitmentCopyModal] = useState(false);
  const [commitments, setCommitments] = useState<ShortCommitmentReadModel[]>([]);
  const [selectedCommitment, setSelectedCommitment] = useState<ShortCommitmentReadModel | null>(null);
  const [showNet, setShowNet] = useState(true);
  const [sortValues, setSortValues] = useState<(boolean | null)[]>([null, null, null, null, null, null]);
  const [isListLoading, setIsListLoading] = useState(true);
  const toggledContractFilters = useToggledContractFilters();

  const navigate = useNavigate()

  const { data: fetchedContracts, isFetching: isFetchingContracts } = useApiGetContractsQuery(
    {
      projectId: loadedProjectId ?? '',
      calculationModelId: loadedVariantId ?? '',
    },
    {
      skip: !loadedProjectId || !loadedVariantId,
    },
  );

  useEffect(() => {
    if (typeof fetchedContracts !== 'undefined') {
      setContracts(fetchedContracts.contracts ?? []);
      setCommitments(fetchedContracts.commitments ?? []);

      let allContracts = fetchedContracts.contracts?.map((contract) => contract) ?? [];
      fetchedContracts.commitments?.forEach((commitment) => {
        allContracts = allContracts.concat(commitment.contracts?.map((contract) => contract) ?? []);
      });
      setAllContracts(allContracts);
    }
  }, [fetchedContracts]);

  const contextItems: ContextMenuItem[] = [
    {
      label: t('projectContract.createContract'),
      subtitle: t('projectContract.createNewContract'),
      icon: <AddIcon />,
      isDisabled: !canWrite,
      onClick: () => {
        setSelectedContract(null);
        setIsOpenNewContractModal(true);
      },
    },
    {
      label: t('common.copyEntity', { entity: t('projectContract.contract') }),
      subtitle: t('projectContract.newContractAsCopy'),
      icon: <Square2StackIcon />,
      isDisabled: contracts.length === 0 || !canWrite,
      onClick: () => {
        setSelectedContract(null);
        setIsOpenContractCopyModal(true);
      },
    },
    {
      label: t('projectContract.createCommitment'),
      subtitle: t('projectContract.createNewCommitment'),
      icon: <AddIcon />,
      isDisabled: !canWrite,
      onClick: () => {
        setSelectedCommitment(null);
        setIsOpenNewCommitmentModal(true);
      },
    },
    {
      label: t('projectContract.contractImport'),
      subtitle: t('projectContract.importContractsDescription'),
      icon: <ArrowDownOnSquareIcon />,
      isDisabled: !canWrite,
      onClick: () => {
        setIsOpenImportContractsSlideOver(true);
      },
    },
  ];

  const openContract = useCallback(
    (contract: ShortContractReadModel, view: ContractViewType) => {
      if (selectedContract !== contract) {
        setSelectedContract(contract);
      }
      if (view === 'details') {
        setIsOpenContractDetailsSlideOver(true);
        navigate(ROUTES_CONFIG.PROJECT_CONTRACT_VIEW.path.replace(':id', loadedProjectId ?? '').replace(':contractId', contract.id));
      } else if (view === 'edit') {
        navigate(ROUTES_CONFIG.PROJECT_CONTRACT_EDIT.path.replace(':id', loadedProjectId ?? '').replace(':contractId', contract.id));
      }
    },
    [selectedContract, navigate, loadedProjectId],
  );

  const openCommitment = useCallback(
    (commitment: ShortCommitmentReadModel) => {
      if (selectedCommitment !== commitment) {
        setSelectedCommitment(commitment);
      }
      navigate(ROUTES_CONFIG.PROJECT_COMMITMENT_VIEW.path.replace(':id', loadedProjectId ?? '').replace(':commitmentId', commitment.id));
    },
    [loadedProjectId, navigate, selectedCommitment],
  );

  const getContractContextMenu = useCallback((contract: ShortContractReadModel) => {
    return [
      {
        label: contract.isPxContract ? t('common.view') : t('common.edit'),
        subtitle: contract.isPxContract ? t('projectContract.viewContract') : t('projectContract.editContract'),
        icon: contract.isPxContract ? <EyeIcon /> : <PencilIcon />,
        onClick: () => {
          setSelectedContract(contract);
          openContract(contract, 'edit');
        },
      },
      {
        label: t('common.copyEntity', { entity: t('projectContract.contract') }),
        subtitle: t('projectContract.newContractAsCopy'),
        icon: <Square2StackIcon />,
        isDisabled: !canWrite,
        onClick: () => {
          setSelectedContract(contract);
          setIsOpenContractCopyModal(true);
        },
      },
      {
        label: t('common.delete'),
        subtitle: t('projectContract.deleteContract'),
        icon: <TrashIcon />,
        isDisabled: !canDelete || contract.isPxContract,
        onClick: () => {
          setSelectedContract(contract);
          setIsOpenContractDeleteModal(true);
        },
      },
    ]
  }, [canWrite, t, canDelete, openContract])

  const contractListItems = useMemo(() => {
    const listItems: ListItemProps[] = [];

    let totalBudget = 0;
    let totalContract = 0;
    let totalForecast = 0;
    let totalInvoice = 0;

    let totalBudgetGross = 0;
    let totalContractGross = 0;
    let totalForecastGross = 0;
    let totalInvoiceGross = 0;

    if (contracts.length) {
      let sortedContracts = [...contracts];

      // apply search value
      if (searchValue) {
        sortedContracts = searchForContract(sortedContracts, searchValue, searchResults, toggledContractFilters)
      }

      // TODO more global sorting
      const sortByIndex = sortValues.findIndex((val) => val !== null);
      if (sortByIndex >= 0) {
        sortedContracts.sort((a, b) => {
          if (sortByIndex === 1) {
            const valA = a.code ?? '';
            const valB = b.code ?? '';
            if (sortValues[sortByIndex]) {
              return valA.localeCompare(valB);
            } else {
              return valB.localeCompare(valA);
            }
          } else if (sortByIndex === 2) {
            const valA = a.contractorName ?? '';
            const valB = b.contractorName ?? '';
            if (sortValues[sortByIndex]) {
              return valA.localeCompare(valB);
            } else {
              return valB.localeCompare(valA);
            }
          } else if (sortByIndex === 3) {
            const valA = a.clientName ?? '';
            const valB = b.clientName ?? '';
            if (sortValues[sortByIndex]) {
              return valA.localeCompare(valB);
            } else {
              return valB.localeCompare(valA);
            }
          } else if (sortByIndex === 5) {
            const valA = a.budgetNet ?? 0;
            const valB = b.budgetNet ?? 0;
            if (sortValues[sortByIndex]) {
              return valA - valB;
            } else {
              return valB - valA;
            }
          } else if (sortByIndex === 6) {
            const valA = a.contractValueNet ?? 0;
            const valB = b.contractValueNet ?? 0;
            if (sortValues[sortByIndex]) {
              return valA - valB;
            } else {
              return valB - valA;
            }
          } else if (sortByIndex === 7) {
            const valA = a.forecastValueNet ?? 0;
            const valB = b.forecastValueNet ?? 0;
            if (sortValues[sortByIndex]) {
              return valA - valB;
            } else {
              return valB - valA;
            }
          } else if (sortByIndex === 8) {
            const valA = a.invoiceValueNet ?? 0;
            const valB = b.invoiceValueNet ?? 0;
            if (sortValues[sortByIndex]) {
              return valA - valB;
            } else {
              return valB - valA;
            }
          }
          return 0;
        });
      }

      sortedContracts.forEach((contract) => {
        totalBudget += contract.budgetNet;
        totalContract += contract.contractValueNet;
        totalForecast += contract.forecastValueNet;
        totalInvoice += contract.invoiceValueNet;

        totalBudgetGross += contract.budgetGross;
        totalContractGross += contract.contractValueGross;
        totalForecastGross += contract.forecastValueGross;
        totalInvoiceGross += contract.invoiceValueGross;

        const contextMenuItems = getContractContextMenu(contract)

        listItems.push({
          id: contract.id,
          onClick: () => {
            setSelectedContract(contract);
            openContract(contract, 'details');
          },
          borderColor: 'bg-slate-400',
          children: <ContractListItem contract={contract} showNet={showNet} searchValue={searchValue} />,
          additionalContent: (
            <div className="hidden md:flex flex-col justify-center py-2 px-4">
              <ContextMenu items={contextMenuItems} />
            </div>
          ),
        });
      });
    }
    return {
      listItems: listItems,
      totalBudget: totalBudget,
      totalContract: totalContract,
      totalForecast: totalForecast,
      totalInvoice: totalInvoice,
      totalBudgetGross: totalBudgetGross,
      totalContractGross: totalContractGross,
      totalForecastGross: totalForecastGross,
      totalInvoiceGross: totalInvoiceGross
    };
  }, [contracts, searchValue, sortValues, searchResults, toggledContractFilters, getContractContextMenu, showNet, openContract]);

  const commitmentListItems = useMemo(() => {
    const listItems: ListItemProps[] = [];

    let totalBudget = 0;
    let totalContract = 0;
    let totalForecast = 0;
    let totalInvoice = 0;

    let totalBudgetGross = 0;
    let totalContractGross = 0;
    let totalForecastGross = 0;
    let totalInvoiceGross = 0;

    if (commitments.length) {
      let sortedCommitments = [...commitments];

      // apply search value
      if (searchValue) {
        const searchFor = searchValue.toLowerCase();
        sortedCommitments = sortedCommitments.filter((commitment) => {
          return (
            toggledContractFilters && toggledContractFilters.length > 0 ? false : (commitment.code.toLowerCase().includes(searchFor) || commitment.name?.toLowerCase().includes(searchFor))
            || (commitment.contracts.length && searchForContract(commitment.contracts, searchValue, searchResults, toggledContractFilters).length)
          );
        });
      }

      // TODO more global sorting
      const sortByIndex = sortValues.findIndex((val) => val !== null);
      if (sortByIndex >= 0) {
        sortedCommitments.sort((a, b) => {
          if (sortByIndex === 1) {
            const valA = a.code ?? '';
            const valB = b.code ?? '';
            if (sortValues[sortByIndex]) {
              return valA.localeCompare(valB);
            } else {
              return valB.localeCompare(valA);
            }
          } else if (sortByIndex === 5) {
            const valA = a.budgetNet ?? 0;
            const valB = b.budgetNet ?? 0;
            if (sortValues[sortByIndex]) {
              return valA - valB;
            } else {
              return valB - valA;
            }
          } else if (sortByIndex === 6) {
            const valA = a.contractValueNet ?? 0;
            const valB = b.contractValueNet ?? 0;
            if (sortValues[sortByIndex]) {
              return valA - valB;
            } else {
              return valB - valA;
            }
          }else if (sortByIndex === 7) {
            const valA = a.forecastValueNet ?? 0;
            const valB = b.forecastValueNet ?? 0;
            if (sortValues[sortByIndex]) {
              return valA - valB;
            } else {
              return valB - valA;
            }
          } else if (sortByIndex === 8) {
            const valA = a.invoiceValueNet ?? 0;
            const valB = b.invoiceValueNet ?? 0;
            if (sortValues[sortByIndex]) {
              return valA - valB;
            } else {
              return valB - valA;
            }
          }
          return 0;
        });
      }

      const sortedCommitmentsWithSortedContracts = sortedCommitments.map((commitment) => {
        if (commitment.contracts) {
          const sortedContracts = [...commitment.contracts];
          sortedContracts.sort((a, b) => {
            if (sortByIndex === 1) {
              const valA = a.code ?? '';
              const valB = b.code ?? '';
              if (sortValues[sortByIndex]) {
                return valA.localeCompare(valB);
              } else {
                return valB.localeCompare(valA);
              }
            } else if (sortByIndex === 5) {
              const valA = a.budgetNet ?? 0;
              const valB = b.budgetNet ?? 0;
              if (sortValues[sortByIndex]) {
                return valA - valB;
              } else {
                return valB - valA;
              }
            } else if (sortByIndex === 6) {
              const valA = a.contractValueNet ?? 0;
              const valB = b.contractValueNet ?? 0;
              if (sortValues[sortByIndex]) {
                return valA - valB;
              } else {
                return valB - valA;
              }
            }else if (sortByIndex === 7) {
              const valA = a.forecastValueNet ?? 0;
              const valB = b.forecastValueNet ?? 0;
              if (sortValues[sortByIndex]) {
                return valA - valB;
              } else {
                return valB - valA;
              }
            } else if (sortByIndex === 8) {
              const valA = a.invoiceValueNet ?? 0;
              const valB = b.invoiceValueNet ?? 0;
              if (sortValues[sortByIndex]) {
                return valA - valB;
              } else {
                return valB - valA;
              }
            }
            return 0;
          });
          return { ...commitment, contracts: sortedContracts };
        } else {
          return commitment;
        }
      });

      sortedCommitmentsWithSortedContracts.forEach((commitment) => {
        totalBudget += commitment.budgetNet;
        totalContract += commitment.contractValueNet;
        totalForecast += commitment.forecastValueNet;
        totalInvoice += commitment.invoiceValueNet;

        totalBudgetGross += commitment.budgetGross;
        totalContractGross += commitment.contractValueGross;
        totalForecastGross += commitment.forecastValueGross;
        totalInvoiceGross += commitment.invoiceValueGross;

        const contextMenuItems: ContextMenuItem[] = [
          {
            label: t('common.edit'),
            subtitle: t('projectContract.editCommitment'),
            icon: <PencilIcon />,
            onClick: () => {
              openCommitment(commitment)
            },
            stopPropagation: true
          },
          {
            label: t('common.delete'),
            subtitle: t('projectContract.deleteCommitment'),
            icon: <TrashIcon />,
            isDisabled: !canDelete,
            onClick: () => {
              setSelectedCommitment(commitment);
              setIsOpenCommitmentDeleteModal(true);
            },
            stopPropagation: true
          },
        ];

        listItems.push({
          id: commitment.id,
          onClick: (e) => {
            e.preventDefault()
            e.stopPropagation()
            openCommitment(commitment)
          },
          borderColor: 'bg-sky-500',
          children: <ContractListItem commitment={commitment} showNet={showNet} searchValue={searchValue} />,
          additionalContent: (
            <div className="hidden md:flex flex-col justify-center py-2 px-4">
              <ContextMenu items={contextMenuItems} />
            </div>
          ),
          listChildren: commitment.contracts.map((contract) => {
            return {
              borderColor: 'bg-slate-400',
              onClick: () => openContract(contract, 'details'),
              children: <ContractListItem contract={contract} showNet={showNet} searchValue={searchValue} child />,
              additionalContent: (
                <div className="hidden md:flex flex-col justify-center py-2 px-4">
                  <ContextMenu items={getContractContextMenu(contract)} />
                </div>
              ),
            };
          }),
          defaultOpen: !!searchValue
        });
      });
    }
    return {
      listItems: listItems,
      totalBudget: totalBudget,
      totalContract: totalContract,
      totalForecast: totalForecast,
      totalInvoice: totalInvoice,
      totalBudgetGross: totalBudgetGross,
      totalContractGross: totalContractGross,
      totalForecastGross: totalForecastGross,
      totalInvoiceGross: totalInvoiceGross
    };
  }, [commitments, searchValue, sortValues, searchResults, toggledContractFilters, t, canDelete, showNet, openCommitment, getContractContextMenu, openContract]);

  const onHandleSort = useCallback(
    (index: number) => {
      const currentSortValues = [...sortValues];
      currentSortValues.forEach((val, i) => {
        if (i !== index) {
          currentSortValues[i] = null;
        }
      });
      const oldValue = currentSortValues[index];
      currentSortValues[index] = !oldValue;
      setSortValues(currentSortValues);
    },
    [sortValues],
  );

  const listSortHeader = useMemo(() => {
    if (contracts.length || commitments.length) {
      return <ContractListSortHeader onHandleSort={onHandleSort} sortValues={sortValues} />;
    }
    return null;
  }, [contracts.length, commitments.length, onHandleSort, sortValues]);

  useEffect(() => {
    if (selectedContract) {
      let foundContract = contracts.find((contract) => contract.id === selectedContract.id);
      if (!foundContract) {
        commitments.forEach((com) => {
          if (com.contracts) {
            foundContract = com.contracts.find((contract) => contract.id === selectedContract.id);
          }
        });
      }
      if (foundContract) {
        setSelectedContract(foundContract);
      }
    }
  }, [selectedContract, contracts, commitments]);
  const sumBudget = useMemo(() => {
    return showNet ? contractListItems.totalBudget + commitmentListItems.totalBudget : contractListItems.totalBudgetGross + commitmentListItems.totalBudgetGross
  }, [showNet, contractListItems, commitmentListItems])

  const sumTotal = useMemo(() => {
    return showNet ? contractListItems.totalContract + commitmentListItems.totalContract : contractListItems.totalContractGross + commitmentListItems.totalContractGross
  }, [showNet, contractListItems, commitmentListItems])


  const sumForecast = useMemo(() => {
    return showNet ? contractListItems.totalForecast + commitmentListItems.totalForecast : contractListItems.totalForecastGross + commitmentListItems.totalForecastGross
  }, [showNet, contractListItems, commitmentListItems])

  const sumInvoice = useMemo(() => {
    return showNet ? contractListItems.totalInvoice + commitmentListItems.totalInvoice : contractListItems.totalInvoiceGross + commitmentListItems.totalInvoiceGross
  }, [showNet, contractListItems, commitmentListItems])

  const foundContracts = useMemo(() => {
    if (searchValue) {
      return [...commitmentListItems.listItems, ...contractListItems.listItems].length
    }
    return allContracts.length
  }, [searchValue, allContracts, commitmentListItems.listItems, contractListItems.listItems])

  return (
    <div className="relative pb-20">
      {(isFetchingContracts || isListLoading) && (
        <LoadingIndicator text={t('projectContract.fetchingContractsLoadingIndicator')} mode="overlay-window" />
      )}
      {foundContracts > 0 && (
        <div className="md:absolute md:right-0 md:top-0 mb-2 md:mb-0">
          <ToggleSwitch
            id="netGrossToggle"
            checked={showNet}
            name="netGrossToggle"
            onChange={() => setShowNet((prev) => !prev)}
            offLabel={t('projectControl.gross')}
            onLabel={t('projectControl.net')}
          />
        </div>
      )}
      <List
        icon={<AddToClipboardIcon className="w-5" />}
        listTitle={t('projectContract.contractsTitle')}
        items={[...commitmentListItems.listItems, ...contractListItems.listItems]}
        emptyMessage={t('projectContract.noContracts')}
        sortHeader={foundContracts ? listSortHeader : undefined}
        updateLoading={() => setIsListLoading(false)}
        showPagination={true}
        amountPerPage={10}
        totalNumber={allContracts.length.toString()}
      >
        {foundContracts > 0 && (
          <ContractListItemSum
            budget={sumBudget}
            total={sumTotal}
            forecast={sumForecast}
            invoice={sumInvoice}
          />
        )}
      </List>

      <FloatingActionButton menuItems={contextItems} />

      <Modal isOpen={isOpenNewContractModal} onClose={() => setIsOpenNewContractModal(false)}>
        <ContractNewWizard onClose={() => setIsOpenNewContractModal(false)} />
      </Modal>

      <Modal isOpen={isOpenNewCommitmentModal} onClose={() => setIsOpenNewCommitmentModal(false)}>
        <CommitmentNewWizard onClose={() => setIsOpenNewCommitmentModal(false)} />
      </Modal>

      <SlideOver
        isOpen={isOpenEditContractSlideOver}
        onClose={() => {
          setIsOpenEditContractSlideOver(false);
          if (!isOpenContractDetailsSlideOver) {
            setSelectedContract(null);
          }
        }}
      >
        <ContractEditSlideOver
          contractId={selectedContract?.id ?? ''}
          commitments={commitments}
          onClose={() => {
            setIsOpenEditContractSlideOver(false);
            if (!isOpenContractDetailsSlideOver) {
              setSelectedContract(null);
            }
          }}
        />
      </SlideOver>

      <SlideOver
        variant='large'
        isOpen={isOpenImportContractsSlideOver}
        onClose={() => setIsOpenImportContractsSlideOver(false)}
      >
        <ImportContractsSlideOver onClose={() => setIsOpenImportContractsSlideOver(false)} />
      </SlideOver>

      <Modal isOpen={isOpenContractDeleteModal} onClose={() => setIsOpenContractDeleteModal(false)}>
        {selectedContract && (
          <ContractDeleteModal
            contract={selectedContract}
            onClose={() => {
              setSelectedContract(null);
              setIsOpenContractDeleteModal(false);
            }}
          />
        )}
      </Modal>

      <Modal isOpen={isOpenContractCopyModal} onClose={() => setIsOpenContractCopyModal(false)}>
        {allContracts.length > 0 && (
          <ContractCopyModal
            onClose={() => {
              setSelectedContract(null);
              setIsOpenContractCopyModal(false);
            }}
            currentContract={selectedContract}
            availableContracts={allContracts}
          />
        )}
      </Modal>

      <Modal isOpen={isOpenCommitmentDeleteModal} onClose={() => setIsOpenCommitmentDeleteModal(false)}>
        {selectedCommitment && (
          <CommitmentDeleteModal
            commitment={selectedCommitment}
            onClose={() => {
              setSelectedCommitment(null);
              setIsOpenCommitmentDeleteModal(false);
            }}
          />
        )}
      </Modal>

      <Routes>
        <Route
          path={ROUTES_CONFIG.PROJECT_CONTRACT_VIEW.name}
          element={
            <ProtectedRoute
              routeConfig={ROUTES_CONFIG.PROJECT_CONTRACT_VIEW}
              projectId={loadedProjectId}
            >
              <ContractView commitments={commitments} view="details" showLoading={!isFetchingContracts && !isListLoading} />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES_CONFIG.PROJECT_CONTRACT_EDIT.name}
          element={
            <ProtectedRoute
              routeConfig={ROUTES_CONFIG.PROJECT_CONTRACT_EDIT}
              projectId={loadedProjectId}
            >
              <ContractView commitments={commitments} view="edit" showLoading={!isFetchingContracts && !isListLoading} />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES_CONFIG.PROJECT_COMMITMENT_VIEW.name}
          element={
            <ProtectedRoute
              routeConfig={ROUTES_CONFIG.PROJECT_COMMITMENT_VIEW}
              projectId={loadedProjectId}
            >
              <CommitmentView />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to='' />} />
      </Routes>
    </div>
  );
};
