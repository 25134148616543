import { InvoicingPartyDto } from '@client/shared/api';
import { formatPercentage, isEmpty } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';
import { Column } from './CalculateContainer';
import { OptionalColumn } from './CalculateSectionHeader';
import { CalculateElementRowInformationColumn } from './CalculateElementRowInformationColumn';
import { CalculateInformationColumnContent } from './CalculateGroupElementInformationColumn';
import { CalculateColumns } from './CalculateColumns';
import React from 'react';
import { TimeLineView } from './Timeline';
import { FormattedCurrency } from '@client/project/shared';
import { CalculateElementRowFinanceColumn } from './CalculateElementRowFinanceColumn';
import { CalculateSelectableColumn } from './CalculateSelectableColumn';

interface CostInvoicingPartyElementProps {
  invoicingParty: InvoicingPartyDto;
  level: number;
  optionalColumn: OptionalColumn;
  obligoColumn: OptionalColumn;
  index: number;
  budget: number;
  restBudget: boolean;
  top?: number;
}

export const CostInvoicingPartyElement = ({
  invoicingParty,
  level,
  optionalColumn,
  obligoColumn,
  index,
  budget,
  restBudget,
  top = 0
}: CostInvoicingPartyElementProps) => {
  const { t } = useTranslation();

  const name = !isEmpty(invoicingParty?.name ?? invoicingParty?.name)
    ? invoicingParty?.name ?? invoicingParty?.name
    : t('common.none');

  return (
    <div
      className="w-full flex group absolute left-0 bg-transparent"
      data-invoicing-party={invoicingParty.code}
      data-cost-element={invoicingParty.costElementId}
      style={{ top: top }}
      title={`${invoicingParty.code} - ${invoicingParty.name}`}
    >
      <CalculateColumns
        view={TimeLineView.FINANCE}
        informationColumnContent={
          <CalculateElementRowInformationColumn
            contentType="row"
            level={level}
            view={TimeLineView.FINANCE}
            leftColumn={
              <CalculateInformationColumnContent
                showCode={true}
                code={invoicingParty.code}
                level={level}
                contentType="invoicingParty"
                restBudget={restBudget}
                index={index}
                showExpanded
              >
                <div className="truncate flex flex-row items-center">
                  <span className="overflow-x-hidden text-ellipsis flex flex-row font-bold">
                    <div className="font-normal mr-1">{t('projectCalculate.invoicingParty')}</div>
                    <div className="mr-1 truncate">{name}</div>
                    {(invoicingParty.budgetValue !== 0 || invoicingParty.budgetValue !== 0) &&
                      budget !== 0 && (
                        <div className="text-xxs opacity-70">
                          {formatPercentage(
                            ((invoicingParty.budgetValue || invoicingParty.budgetValue) ?? 0) / budget,
                            { maxDigits: 0 }
                          )}
                        </div>
                      )}
                  </span>
                </div>
              </CalculateInformationColumnContent>
            }
            rightColumn={<>right column</>}
          />
        }
        currentColumnContent={
          <CalculateElementRowFinanceColumn
            level={level}
            columnType={Column.CURRENT}
            view={TimeLineView.FINANCE}
          >
            {!!invoicingParty.budgetValue && <FormattedCurrency amount={invoicingParty.budgetValue} />}
          </CalculateElementRowFinanceColumn>
        }
        obligoColumnContent={
          <CalculateSelectableColumn
            column={obligoColumn}
            level={level}
            columnType={Column.OBLIGO}
            view={TimeLineView.FINANCE}
            values={invoicingParty}
            type="invoice"
          />
        }
        optionalColumnContent={
          <CalculateSelectableColumn
            column={optionalColumn}
            level={level}
            columnType={Column.OPTIONAL_COLUMN}
            view={TimeLineView.FINANCE}
            values={invoicingParty}
            type="invoice"
          />
        }
      />
    </div>
  );
};
