import {
  Badge,
  Button,
  CheckmarkFilledIcon,
  ContextMenu,
  ContextMenuItem,
  DecoratedCard,
  ListTitle,
  LoadingIndicator,
  SlideOver,
  SlideOverOnCloseProps,
  SlideOverWithTabs,
  UsDollarCircledIcon,
} from '@client/shared/toolkit';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContractDetails } from './ContractDetails';
import { ContractTitles } from './ContractTitles';
import {
  ShortCommitmentReadModel,
  ContractReadModel,
  useApiGetContractQuery,
  useApiPostReopenContractMutation,
  useApiPostSettleContractMutation,
  useApiPostPayContractMutation,
} from '@client/shared/api';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { ContractInvoices } from './ContractInvoice';
import { ContractDocuments } from './ContractDocuments';
import { useValidateProjectPermission } from '@client/shared/permissions';
import { ContractBudgeting } from './';
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { isEmpty, safeMutation } from '@client/shared/utilities';
import { AuditLog, ContractEditSlideOver } from '../..';

export interface ContractSlideOverProps extends SlideOverOnCloseProps {
  contractId: string;
  selectedContract?: ContractReadModel | null;
  setChildSlideOverIsOpen: (val: boolean) => void;
  commitments?: ShortCommitmentReadModel[] | null;
}

export const ContractSlideOver = (props: ContractSlideOverProps) => {
  const { onClose, contractId, selectedContract, setChildSlideOverIsOpen } = props;
  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const canWrite = useValidateProjectPermission(['CONTRACT_WRITE'], loadedProjectId ?? '');
  const canDelete = useValidateProjectPermission(['CONTRACT_DELETE'], loadedProjectId ?? '');

  const [settleContract, { isLoading: isSettling }] = useApiPostSettleContractMutation();
  const [payContract, { isLoading: isPaying }] = useApiPostPayContractMutation();
  const [reopenContract, { isLoading: isReopening }] = useApiPostReopenContractMutation();

  const [isOpenEditContractSlideOver, setIsOpenEditContractSlideOver] = useState(false);
  const [selectedField, setSelectedField] = useState<string | null>(null);
  const [contract, setContract] = useState<ContractReadModel | null>(selectedContract ?? null);

  const canReopen = contract?.state === 'Settled' || contract?.state === 'Paid';
  const canSettle = contract?.state === 'Pending';
  const canPay = contract?.state === 'Pending' || contract?.state === 'Settled';

  const handleSettling = async () => {
    if (loadedProjectId && loadedVariantId && contract) {
      try {
        await safeMutation(
          settleContract,
          {
            contractId: contract.id,
            projectId: loadedProjectId,
            calculationModelId: loadedVariantId,
          }, isSettling)
      } catch (e) {
        console.error(e);
      }
    }
  }

  const handlePaying = async () => {
    if (loadedProjectId && loadedVariantId && contract) {
      try {
        await safeMutation(
          payContract,
          {
            contractId: contract.id,
            projectId: loadedProjectId,
            calculationModelId: loadedVariantId,
          }, isPaying)
      } catch (e) {
        console.error(e);
      }
    }
  }

  const handleReopening = async () => {
    if (loadedProjectId && loadedVariantId && contract) {
      try {
        await safeMutation(
          reopenContract,
          {
            contractId: contract.id,
            projectId: loadedProjectId,
            calculationModelId: loadedVariantId,
          }, isReopening)
      } catch (e) {
        console.error(e);
      }
    }
  }

  const contextItems: ContextMenuItem[] = [
    {
      label: t('projectContract.reopen'),
      icon: <ArrowUturnLeftIcon className={classNames('h-5 w-5', canWrite && canReopen ? 'text-yellow-500' : 'text-slate-300')}/>,
      isDisabled: !canWrite || !canReopen,
      onClick: () => handleReopening(),
    },
    {
      label: t('projectContract.settle'),
      icon: <CheckmarkFilledIcon className={classNames('h-5 w-5', canWrite && canSettle ? 'text-emerald-500' : 'text-slate-300')}/>,
      isDisabled: !canWrite || !canSettle,
      onClick: () => handleSettling(),
    },
    {
      label: t('projectContract.pay'),
      icon: <UsDollarCircledIcon className={classNames('h-5 w-5', canWrite && canPay ? 'text-sky-700' : 'text-slate-300')}/>,
      isDisabled: !canWrite || !canPay,
      onClick: () => handlePaying(),
    },
  ];

  const { data: loadedContract, isFetching: isLoadingContract } = useApiGetContractQuery(
    {
      contractId: contractId,
      projectId: loadedProjectId ?? '',
      calculationModelId: loadedVariantId ?? '',
    },
    {
      skip: !loadedProjectId || !loadedVariantId || !isEmpty(selectedContract),
    },
  );

  useEffect(() => {
    if (typeof loadedContract !== 'undefined' && loadedContract !== null) {
      setContract(loadedContract);
    }
  }, [loadedContract]);

  useEffect(() => {
    if (selectedContract && !loadedContract) {
      setContract(selectedContract);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContract]);

  const tabOptions = useMemo(() => {
    if (!contract) return [];
    return [
      {
        header: t('projectContract.contract'),
        name: 'contract',
        panel: (
          <div className="flex flex-col divide-y-2 gap-9">
            <ContractDetails
              contract={contract}
              handleEditButtonClick={(field) => {
                setSelectedField(field);
                setIsOpenEditContractSlideOver(true);
              }}
            />
            <ContractBudgeting contract={contract} />
            <ContractTitles contract={contract} setChildSlideOverIsOpen={setChildSlideOverIsOpen} />
          </div>
        ),
      },
      {
        header: t('projectContract.invoices'),
        name: 'invoices',
        panel: <ContractInvoices contract={contract} setChildSlideOverIsOpen={setChildSlideOverIsOpen} />,
      },
      {
        header: (
          <>
            {t('projectContract.documents')}{' '}
            {contract.documents?.length > 0 && (
              <Badge variant="lightInfo" text={contract.documents?.length.toString()} />
            )}
          </>
        ),
        name: 'documents',
        panel: (
          <ContractDocuments
            contractId={contract.id}
            documents={contract.documents}
            canWrite={canWrite}
            canDelete={canDelete}
          />
          // <ListWrapper>
          //   <ListTitle title={t('projectContract.documents')} />
          //   {contract.documents && contract.documents.map((doc, index) =>
          //     <p key={index}>{doc.name}</p>
          //   )}
          // </ListWrapper>
        ),
      },
      {
        header: t('projectContract.risks'),
        name: 'risks',
        panel: (
          <DecoratedCard shadowVariant="normal">
            <DecoratedCard.Content>
              <ListTitle title={t('projectContract.risks')} />
            </DecoratedCard.Content>
          </DecoratedCard>
        ),
      },
      {
        header: t('projectContract.history'),
        name: 'contractHistory',
        panel: (
          <AuditLog
            id={contract.logicalId ?? ''}
            targetType='Contract'
          />
        ),
      },
      {
        header: t('projectContract.chat'),
        name: 'chat',
        panel: (
          <DecoratedCard shadowVariant="normal">
            <DecoratedCard.Content>
              <ListTitle title={t('projectContract.chat')} />
            </DecoratedCard.Content>
          </DecoratedCard>
        ),
      },
    ];
  }, [t, contract, setChildSlideOverIsOpen, canWrite, canDelete]);

  return (
    <>
      <SlideOverWithTabs
        tabOptions={tabOptions}
        title={contract?.name}
        subtitle={
          <>
            {t('projectContract.contract')}&nbsp;<span className="font-bold">{contract?.code}</span>
          </>
        }
        onClose={onClose}
      >
        {(isLoadingContract || isSettling || isPaying || isReopening) && (
          <LoadingIndicator text={t('projectContract.fetchingContractLoadingIndicator')} mode="overlay" />
        )}
        <SlideOver.Controls>
          <div className='flex flex-grow justify-between'>
            <div className='items-start'>
              {(canPay || canSettle || canReopen) && !contract?.isPxContract &&
                <ContextMenu
                  items={contextItems}
                  button={
                    <Button variant='success' className='mr-2'>
                      {t('projectContract.updateState')}
                    </Button>
                  }
                />
              }
            </div>

            <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
              {t('common.close')}
            </Button>
          </div>
        </SlideOver.Controls>
      </SlideOverWithTabs>
      <SlideOver
        isOpen={isOpenEditContractSlideOver}
        onClose={() => {
          setIsOpenEditContractSlideOver(false);
        }}
        onAfterLeave={() => setSelectedField(null)}
      >
        <ContractEditSlideOver
          contractId={contractId}
          selectedContract={contract}
          commitments={props.commitments != null ? props.commitments : (props.commitments === null ? [] : undefined)}
          field={selectedField}
          onClose={() => {
            setIsOpenEditContractSlideOver(false);
          }}
        />
      </SlideOver>
    </>
  );
};
