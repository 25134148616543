import { useTranslation } from 'react-i18next';
import { TimeLineView } from './Timeline';
import { Column } from './CalculateContainer';
import { CalculateElementRowInformationColumn } from './CalculateElementRowInformationColumn';
import { CalculateInformationColumnContent } from './CalculateGroupElementInformationColumn';
import { CalculateColumns } from './CalculateColumns';
import classNames from 'classnames';
import { FormattedCurrency } from '@client/project/shared';
import { CalculateElementRowFinanceColumn } from './CalculateElementRowFinanceColumn';

interface CostRestElementProps {
  restBudget?: number | null;
  level: number;
  contracts: boolean;
  type: 'row' | 'group';
  top?: number;
}

export const CostRestElement = ({
  restBudget,
  level,
  contracts,
  type,
  top
}: CostRestElementProps) => {
  const { t } = useTranslation();

  return (
    <div className="w-full flex group absolute left-0 bg-transparent" style={{ top: top }}>
      <CalculateColumns
        view={TimeLineView.FINANCE}
        informationColumnContent={
          <CalculateElementRowInformationColumn
            contentType={type}
            level={level + 1}
            view={TimeLineView.FINANCE}
            leftColumn={
              <CalculateInformationColumnContent
                level={level + 1}
                contentType={type}
                showExpanded
                marker={
                  <div className="relative w-[0.5625rem] flex-none">
                    {!contracts ? (
                      <>
                        <div className="w-px h-8 absolute ml-1 -mt-9 bg-slate-400 z-0" />
                        <div className="w-[0.5625rem] h-[0.5625rem] p-px absolute -mt-1 rounded-full bg-white shadow border-2 border-slate-400 z-[5]" />
                      </>
                    ) : (
                      <>
                        <div className="w-px h-10 absolute ml-1 -mt-9 bg-slate-400 z-0" />
                        <div className="ml-[4px] w-[9px] h-[10px] absolute -mt-[22px] border-slate-400 z-1 rounded-bl-2xl border-l border-b" />
                        <div className="ml-[12px] w-[9px] h-[10px] absolute -mt-[13px] border-slate-400 z-1 rounded-tr-2xl border-r border-t" />
                        <div className="ml-4 w-[0.5625rem] h-[0.5625rem] p-px absolute -mt-1 rounded-full bg-white shadow border-2 border-slate-400 z-[5]" />
                      </>
                    )}
                  </div>
                }
              >
                <div
                  className={classNames(
                    'truncate flex flex-row items-center overflow-x-hidden text-ellipsis font-bold',
                    {
                      'ml-4': contracts,
                    }
                  )}
                >
                  {t('projectCalculate.rest')}
                </div>
              </CalculateInformationColumnContent>
            }
            rightColumn={<div></div>}
          />
        }
        currentColumnContent={
          <CalculateElementRowFinanceColumn
            level={level + 1}
            columnType={Column.CURRENT}
            view={TimeLineView.FINANCE}
          >
            {restBudget && <FormattedCurrency amount={restBudget} />}
          </CalculateElementRowFinanceColumn>
        }
        obligoColumnContent={
          <CalculateElementRowFinanceColumn
            level={level + 1}
            columnType={Column.OBLIGO}
            view={TimeLineView.FINANCE}
          />
        }
        optionalColumnContent={
          <CalculateElementRowFinanceColumn
            level={level + 1}
            columnType={Column.OPTIONAL_COLUMN}
            view={TimeLineView.FINANCE}
          />
        }
      />
    </div>
  );
};
