import { TimeLineDistributionsModal } from '../..';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { useApiGetCommitmentQuery } from '@client/shared/api';
import { ModalOnCloseProps } from '@client/shared/toolkit';

interface CommitmentTimeLineDistributionModalProps extends ModalOnCloseProps {
  commitmentId?: string;
  selectedDate?: string;
}

export const CommitmentTimeLineDistributionModal = ({
  commitmentId,
  selectedDate,
  onClose,
}: CommitmentTimeLineDistributionModalProps) => {
  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const { data: commitment, isFetching, isError } = useApiGetCommitmentQuery(
    {
      commitmentId: commitmentId ?? '',
      projectId: loadedProjectId ?? '',
      calculationModelId: loadedVariantId ?? '',
    },
    {
      skip: !loadedProjectId || !loadedVariantId || !commitmentId,
    },
  );

  if (!commitment?.calculatedTimeline || isError) return null;

  return (
    <TimeLineDistributionsModal
      useExistingEffectiveDistributionValues={true}
      timeline={commitment.calculatedTimeline}
      selectedDate={selectedDate}
      onClose={() => {
        onClose(false)
      }}
      isLoading={isFetching}
    />
  );
};
