import React, { createContext, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { Location } from 'react-router-dom';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { ReportExportCustomComponent, ReportExportVariable } from '@client/shared/utilities';

export type ReportExportFilterItem = {
  column: string;
  value1?: string;
  condition?:
    | 'EqualTo'
    | 'NotEqualTo'
    | 'GreaterThan'
    | 'GreaterThanOrEqualTo'
    | 'LessThan'
    | 'LessThanOrEqualTo'
    | 'Between'
    | 'NotBetween'
    | 'Containing'
    | 'NotContaining'
    | 'BeginningWith'
    | 'EndingWith'
    | 'IsNull'
    | 'IsNotNull'; // StiFilterCondition
  dataType?: 'String' | 'Numeric' | 'DateTime' | 'Boolean' | 'Expression'; // StiFilterDataType
  value2?: string;
  expression?: string;
  item?: number;
};
export type ReportExportFilter = {
  componentName: string;
  filterMode: 'And' | 'Or'; // StiFilterMode
  filters: ReportExportFilterItem[];
};
export type ReportExportFilterConfig = ReportExportFilter[];

export type ReportingContextType = {
  onDownload: () => void;
  sourceCalculationModelId?: string;
  setSourceCalculationModelId: (id: string) => void;
  targetCalculationModelId?: string;
  setTargetCalculationModelId: (id: string) => void;
  calculationModelId?: string;
  setCalculationModelId: (id: string) => void;
  datasourceParameters?: string | null;
  setDatasourceParameters: (params: string | null) => void;
  exportFilterConfig?: ReportExportFilterConfig;
  setExportFilterConfig: (config: ReportExportFilterConfig | undefined) => void;
  exportVariables?: ReportExportVariable[];
  setExportVariables: (variables: ReportExportVariable[] | undefined) => void;
  exportCustomComponents?: ReportExportCustomComponent[];
  setExportCustomComponents: (components: ReportExportCustomComponent[] | undefined) => void;
};
export const ReportingContext = createContext<ReportingContextType>({
  sourceCalculationModelId: undefined,
  setSourceCalculationModelId: () => {
    //
  },
  targetCalculationModelId: undefined,
  setTargetCalculationModelId: () => {
    //
  },
  calculationModelId: undefined,
  setCalculationModelId: () => {
    //
  },
  onDownload: () => {
    //
  },
  datasourceParameters: undefined,
  setDatasourceParameters: () => {
    //
  },
  exportFilterConfig: undefined,
  setExportFilterConfig: () => {
    //
  },
  exportVariables: undefined,
  setExportVariables: () => {
    //
  },
  exportCustomComponents: undefined,
  setExportCustomComponents: () => {
    //
  }
});

interface ReportingContextProviderProps extends PropsWithChildren {
  projectId?: string;
  activeVariantId?: string;
  location: Location;
}

export const ReportingContextProvider = ({
  projectId,
  activeVariantId,
  location,
  children,
}: ReportingContextProviderProps) => {
  const [sourceCalculationModelId, setSourceCalculationModelId] = useState<string | undefined>(activeVariantId);
  const [targetCalculationModelId, setTargetCalculationModelId] = useState<string | undefined>(activeVariantId);
  const [calculationModelId, setCalculationModelId] = React.useState<string | undefined>(activeVariantId);
  const [datasourceParameters, setDatasourceParameters] = useState<string | null>(null);
  const [exportFilterConfig, setExportFilterConfig] = useState<ReportExportFilterConfig | undefined>();
  const [exportVariables, setExportVariables] = useState<ReportExportVariable[] | undefined>();
  const [exportCustomComponents, setExportCustomComponents] = useState<ReportExportCustomComponent[] | undefined>();

  const onDownload = useCallback(() => {
    const currentPath = location.pathname;
    if (
      projectId &&
      ROUTES_CONFIG.REPORTING_COMPARISON.path.replace(':id', projectId) === currentPath &&
      sourceCalculationModelId &&
      targetCalculationModelId
    ) {
      window.open(
        `/api/projects/${projectId}/calculation-models/${sourceCalculationModelId}/reporting/comparison/${targetCalculationModelId}/excel`,
        '_new',
      );
    } else if (
      projectId &&
      calculationModelId &&
      ROUTES_CONFIG.REPORTING_FORECAST.path.replace(':id', projectId) === currentPath
    ) {
      window.open(
        `/api/projects/${projectId}/calculation-models/${calculationModelId}/reporting/forecast/excel`,
        '_new',
      );
    } else if (
      projectId &&
      ROUTES_CONFIG.REPORTING_BUDGET.path.replace(':id', projectId) === currentPath &&
      sourceCalculationModelId
    ) {
      window.open(
        `/api/projects/${projectId}/calculation-models/${sourceCalculationModelId}/reporting/budget/excel`,
        '_new',
      );
    }
  }, [location.pathname, projectId, sourceCalculationModelId, targetCalculationModelId, calculationModelId]);

  const value = useMemo(
    () => ({
      sourceCalculationModelId,
      setSourceCalculationModelId,
      targetCalculationModelId,
      setTargetCalculationModelId,
      calculationModelId,
      setCalculationModelId,
      onDownload,
      datasourceParameters,
      setDatasourceParameters,
      exportFilterConfig,
      setExportFilterConfig,
      exportVariables,
      setExportVariables,
      exportCustomComponents,
      setExportCustomComponents,
    }),
    [sourceCalculationModelId, targetCalculationModelId, calculationModelId, onDownload, datasourceParameters, exportFilterConfig, setCalculationModelId, exportVariables, exportCustomComponents]
  );

  return <ReportingContext.Provider value={value}>{children}</ReportingContext.Provider>;
};
