import {
  isTimelineElementInYear,
  TimeLineElementFinance,
  // TimeLineElementFinanceContract,
  TimeLineElementsProps,
} from '.';
import React, { useCallback, useMemo } from 'react';
import { DatedPaymentGroupDto, FinanceTimeline } from '@client/shared/api';


export interface TimeLineElementFinanceData extends TimeLineElementsProps {
  // TODO check if this is even needed or if we can just use TimeLineElementsProps
  datedPayments?: DatedPaymentGroupDto[];
  budget?: number;
  forecast?: number;
  payment?: number;
}

type TimeLineBodyFinanceRowsProps = {
  data: TimeLineElementsProps;
  timelineStartDate: Date;
  timelineEndDate: Date;
  year: number;
  groupName: string;
  expandedIds: string[];
  idx: number;
};

export const TimeLineBodyFinanceRows = (props: TimeLineBodyFinanceRowsProps) => {
  const { data, timelineStartDate, timelineEndDate, year, groupName, expandedIds, idx } = props;

  const rows: { [key: string]: TimeLineElementFinanceData[] } = useMemo(() => {
    let commitmentRows: TimeLineElementFinanceData[] = [];
    let contractRows: TimeLineElementFinanceData[] = [];
    let invoicingPartyRows: TimeLineElementFinanceData[] = [];

    /* COMMITMENTS */
    if (data.commitments && data.commitments.length) {
      commitmentRows = data.commitments.map((commitment) => {
        return {
          datedPayments: commitment?.datedPayments,
          budget: commitment?.budgetValue,
          forecast: commitment?.forecastValue,
          payment: commitment?.paymentValue,
          financeTimeline: commitment?.financeTimeline,
          type: 'element',
          description: commitment?.name,
          elementType: 'commitment', // needed to open calculated timeline modal
          elementId: commitment?.id, // needed to open calculated timeline modal
        };
      });
    }

    /* CONTRACTS */
    if (data.contracts && data.contracts.length) {
      contractRows = data.contracts.map((contract) => {
        return {
          datedPayments: contract?.datedPayments,
          budget: contract?.budgetValue,
          forecast: contract?.forecastValue,
          payment: contract?.paymentValue,
          financeTimeline: contract?.financeTimeline,
          type: 'element',
          description: contract?.name,
          elementType: 'contract', // needed to open timeline distribution modal
          elementId: contract?.id, // needed to open timeline distribution modal
        };
      });
    }

    /* INVOICING PARTIES */
    if (data.invoicingParties && data.invoicingParties.length) {
      invoicingPartyRows = data.invoicingParties.map((invoicingParty) => {
        return {
          datedPayments: invoicingParty?.datedPayments,
          budget: invoicingParty?.budgetValue,
          forecast: invoicingParty?.forecastValue,
          payment: invoicingParty?.paymentValue,
          type: 'element',
          description: invoicingParty?.name,
          elementType: 'invoicingParty',
          elementId: invoicingParty?.id
        };
      });
    }

    return {
      commitment: commitmentRows,
      contract: contractRows,
      'invoicing-party': invoicingPartyRows,
    };
  }, [data.commitments, data.contracts, data.invoicingParties]);

  return (
    <>
      {/* REST ROW */}
      {data.restBudget &&
        data.elementId &&
        ((data.type === 'element' && data.parentId && expandedIds?.includes(data.parentId)) ||
          (data.type === 'group' && expandedIds?.includes(data.id ?? data.elementId))) && (
          <div data-rest="true" data-element-id={data.elementId} className="h-[38px] w-full" />
        )}
      {/* CONTRACT ROWS */}
      {Object.keys(rows).map((key, index) => {
        return (
          <TimeLineBodyFinanceContractRows
            key={`tl-contract-rows-${key}`}
            rows={rows[key]}
            timelineStartDate={timelineStartDate}
            timelineEndDate={timelineEndDate}
            data={data}
            year={year}
            groupName={groupName}
            idx={idx}
            expandedIds={expandedIds}
            type={key}
          />
        );
      })}
    </>
  );
};

export interface TimeLineBodyFinanceContractRowsProps extends TimeLineBodyFinanceRowsProps {
  rows: TimeLineElementFinanceData[];
  type: string;
}

export const TimeLineBodyFinanceContractRows = (props: TimeLineBodyFinanceContractRowsProps) => {
  const { data, rows, expandedIds, type, groupName, idx, timelineStartDate, timelineEndDate, year } = props;

  const isInYear = useCallback(
    (financeTimeline: FinanceTimeline) => {
      if (financeTimeline) {
        return isTimelineElementInYear(year, financeTimeline.start, financeTimeline.end);
      }
      return false;
    },
    [year],
  );

  return (
    <>
      {rows.length > 0 &&
        rows.map((row, cIdx) => {
          if (
            data.elementId &&
            ((data.type === 'element' && data.parentId && expandedIds?.includes(data.parentId)) ||
              (data.type === 'group' && expandedIds?.includes(data.id ?? data.elementId)))
          ) {
            return (
              <div
                className="relative h-[38px] w-full z-30 pointer-events-auto"
                data-finance-element-id={data.elementId}
                data-row-id={row.id}
                data-row-type={type}
                key={`timeline-body-element-${groupName}-${idx}-${type}-${cIdx}`}
              >
                {row.financeTimeline && isInYear(row.financeTimeline) && (
                  <>
                    <TimeLineElementFinance
                      timeLineStartDate={timelineStartDate}
                      timeLineEndDate={timelineEndDate}
                      financeTimeline={row.financeTimeline}
                      year={year}
                      timeLineElement={row}
                      group={groupName}
                    />
                    {/* TODO check if needed */}
                    {/*<TimeLineElementFinanceContract
                      timeLineStartDate={timelineStartDate}
                      timeLineEndDate={timelineEndDate}
                      financeTimeline={row.financeTimeline}
                      data={row}
                      year={year}
                    /> */}
                    </>
                )}
              </div>
            );
          }
          return null;
        })}
    </>
  );
};
