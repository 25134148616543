import { AddButton, AddButtonProps } from '.';

export const SettingsAddButton = (props: AddButtonProps) => {
  return (
    <div className="flex w-full justify-end items-center mt-2">
      <div className="w-11 h-11">
        <AddButton variant="primary" {...props} />
      </div>
    </div>
  );
};
