import { useNavigate, useParams } from 'react-router-dom';
import { CommitmentSlideOver } from '@client/project/shared';
import { LoadingIndicator, SlideOver } from '@client/shared/toolkit';
import React, { useEffect, useState } from 'react';
import { ShortCommitmentReadModel, ShortContractReadModel, useApiGetContractsQuery } from '@client/shared/api';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { useTranslation } from 'react-i18next';

export const CommitmentView = () => {
  const { commitmentId } = useParams()

  const navigate = useNavigate();
  const { t } = useTranslation()

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const [isOpenCommitmentDetailsSlideOver, setIsOpenCommitmentDetailsSlideOver] = useState(false)
  const [isChildSlideOverIsOpen, setIsChildSlideOverOpen] = useState(false);
  const [commitment, setCommitment] = useState<ShortCommitmentReadModel | null>(null);
  const [contracts, setContracts] = useState<ShortContractReadModel[] | null>(null);

  if (!commitmentId) {
    navigate(ROUTES_CONFIG.PROJECT_CONTRACT.name);
  }

  const { data: fetchedContracts, isFetching: isFetchingContracts, isError } = useApiGetContractsQuery(
    {
      projectId: loadedProjectId ?? '',
      calculationModelId: loadedVariantId ?? '',
    },
    {
      skip: !loadedProjectId || !loadedVariantId,
    },
  );

  useEffect(() => {
    if (typeof fetchedContracts !== 'undefined' && fetchedContracts !== null && commitmentId && fetchedContracts.commitments && !isFetchingContracts) {
      const foundCommitment = fetchedContracts.commitments.find((x: ShortCommitmentReadModel) => x.id === commitmentId) ?? null;
      if (!foundCommitment) {
        navigate(ROUTES_CONFIG.PROJECT_CONTRACT.name);
      }
      setCommitment(foundCommitment);
      setContracts(fetchedContracts.contracts ?? []);
      if (!contracts) {
        setIsOpenCommitmentDetailsSlideOver(true)
      }
    }
    if (isError) {
      navigate(ROUTES_CONFIG.PROJECT_CONTRACT.name);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedContracts, isError]);

  return (
    <SlideOver
      isOpen={isOpenCommitmentDetailsSlideOver}
      onClose={() => setIsOpenCommitmentDetailsSlideOver(false)}
      variant="2xl"
      confirmBeforeClose={false}
      preventClickOutsideClose={isChildSlideOverIsOpen}
      onAfterLeave={() => {
        navigate(ROUTES_CONFIG.PROJECT_CONTRACT.name);
      }}
    >
      {isFetchingContracts && (
        <LoadingIndicator text={t('projectContract.fetchingCommitmentLoadingIndicator')} mode="overlay" />
      )}
      {commitment && !isFetchingContracts && (
        <CommitmentSlideOver
          setChildSlideOverIsOpen={setIsChildSlideOverOpen}
          shortCommitment={commitment}
          contracts={contracts}
          onClose={() => {
            setIsOpenCommitmentDetailsSlideOver(false)
          }}
        />
      )}
    </SlideOver>
  )
}
