import { LevelToggle } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { CalculationModelMetadata } from '@client/shared/api';
import React, { useContext, useMemo } from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { useLoadedProjectVariants, useLoadedVariantId } from '@client/project/store';
import { MIN_TIMELINE_HEADER_HEIGHT, TimeLineDataContext } from './Timeline';
import cn from 'classnames';
import classNames from 'classnames';
import { Column } from './CalculateContainer';
import {CalculateContext} from "./CalculateContext";

export interface CalculateSectionHeaderProps {
  archivedVersions?: CalculationModelMetadata[];
  selectedVersionId?: string;
  setSelectedVersionId: (VersionId: string) => void;
  optionalColumn: OptionalColumn;
  setOptionalColumn: (column: OptionalColumn) => void;
  obligoColumn: OptionalColumn;
  setObligoColumn: (column: OptionalColumn) => void;
  title: string;
  icon: React.ReactNode;
  onCollapse: (level: number) => void;
  onLevelToggle: (state: boolean) => void;
  level?: number;
  showLevelToggles?: boolean;
  maxLevel?: number;
}

export enum OptionalColumn {
  CONTRACT = 'contract',
  INVOICE = 'invoice',
  SUPPLEMENT = 'supplement',
  TOTAL_CONTRACT = 'totalContract',
  FORECAST = 'forecast',
  ABSOLUTE_PAYMENT = 'absolutePayment',
  PAYMENT = 'payment',
  OBLIGO = 'obligo'
}

export enum SectionTitle {
  COSTS = 'costs',
  RISKS = 'risks',
  EARNINGS = 'earnings',
  FINANCING = 'financing',
  NONE = 'none',
}

const SECTION_TITLES = ['costs', 'risks', 'earnings', 'financing'];

export const CalculateSectionHeader = ({
  optionalColumn,
  setOptionalColumn,
  obligoColumn,
  setObligoColumn,
  title,
  icon,
  onCollapse,
  onLevelToggle,
  level,
  showLevelToggles,
  maxLevel = 3
}: CalculateSectionHeaderProps) => {
  const { setIsLoading } = useContext(CalculateContext);

  // hacky temporary solution, Ben takes the blame for that
  const handleOnCollapse = (clickedLevel: number) => {
    if (title === SectionTitle.COSTS) {
      if (level !== clickedLevel) {
        setIsLoading(true)
      }
      setTimeout(() => {
        onCollapse(clickedLevel);
      }, 1)
    } else {
      onCollapse(clickedLevel);
    }
  };

  const handleLevelToggle = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    evt.stopPropagation();

    onLevelToggle(true);
  };

  const { t } = useTranslation();

  const loadedVariantId = useLoadedVariantId();
  const loadedProjectVariants = useLoadedProjectVariants();
  const latestVariant = loadedProjectVariants.data.find((v) => v.type === 'Version')?.id;
  const isCurrentVersion = loadedVariantId === latestVariant;
  const { headerHeight } = useContext(TimeLineDataContext);

  return (
    <div
      className={cn('w-full flex items-center h-16 cursor-default sticky top-0 z-10', 'transition-top duration-300', {
        'border-l': !SECTION_TITLES.includes(title)
      })}
      style={{
        // needed to align the headlines correctly
        top: headerHeight ? headerHeight - MIN_TIMELINE_HEADER_HEIGHT : undefined
      }}
    >
      {SECTION_TITLES.includes(title) && (
        <div className="flex flex-row items-center cursor-default w-full h-full">
          <button className="flex items-center text-2xl cursor-pointer" onClick={handleLevelToggle}>
            {icon}
            <span className="ml-4 font-medium">
              {title === SectionTitle.COSTS && t('projectCalculate.calculateCostsTitle')}
              {title === SectionTitle.RISKS && t('projectCalculate.calculateRiskTitle')}
              {title === SectionTitle.EARNINGS && t('projectCalculate.calculateEarningsTitle')}
              {title === SectionTitle.FINANCING && t('projectCalculate.calculateFinancingTitle')}
            </span>
          </button>
          {showLevelToggles && (
            <LevelToggle handleOnCollapse={handleOnCollapse} showLevels={Array.from({length: maxLevel}, (_, i) => i + 1)} />
          )}
        </div>
      )}
      {title === Column.CURRENT && (
        <div
          className="w-full h-full flex items-center justify-end mr-3"
          title={!isCurrentVersion ? t('projectCalculate.headerTitleCurrentForecastWarning') : ''}
        >
          {!isCurrentVersion && <ExclamationTriangleIcon className="h-6 w-6 text-red-900 flex-shrink-0" />}
          <span className="text-[15px] text-right font-bold ml-1">
            {t('projectCalculate.headerTitleCurrentBudget')}
          </span>
        </div>
      )}
      {title === Column.OBLIGO && (
        <CalculateSectionHeaderSelectableColumn
          selectedItem={obligoColumn}
          setSelectedItem={setObligoColumn}
        />
      )}
      {title === Column.OPTIONAL_COLUMN && (
        <CalculateSectionHeaderSelectableColumn
          selectedItem={optionalColumn}
          setSelectedItem={setOptionalColumn}
        />
      )}
      {title === SectionTitle.NONE && <div></div>}
    </div>
  );
};

type CalculateSectionHeaderSelectableColumnProps = {
  selectedItem: OptionalColumn
  setSelectedItem: (column: OptionalColumn) => void
}
const CalculateSectionHeaderSelectableColumn = (props: CalculateSectionHeaderSelectableColumnProps) => {
  const {
    selectedItem,
    setSelectedItem
  } = props
  const { t } = useTranslation();

  const allDropdownItems = useMemo(() => {
    return [
      {
        label: t('projectCalculate.obligo'),
        name: OptionalColumn.OBLIGO,
        enabled: true,
      },
      {
        label: t('projectCalculate.headerTitleForecast'),
        name: OptionalColumn.FORECAST,
        enabled: true,
      },
      {
        label: t('projectCalculate.headerTitleAbsolutePayment'),
        name: OptionalColumn.ABSOLUTE_PAYMENT,
        enabled: true,
      },
      {
        label: t('projectCalculate.headerTitlePayment'),
        name: OptionalColumn.PAYMENT,
        enabled: true,
      },
      {
        label: t('projectCalculate.financing.mainContract'),
        name: OptionalColumn.CONTRACT,
        enabled: true,
      },
      {
        label: t('projectCalculate.financing.invoice'),
        name: OptionalColumn.INVOICE,
        enabled: true,
      },
      {
        label: t('projectCalculate.financing.supplement'),
        name: OptionalColumn.SUPPLEMENT,
        enabled: true,
      },
      {
        label: t('projectCalculate.financing.totalContract'),
        name: OptionalColumn.TOTAL_CONTRACT,
        enabled: true,
      },
    ]
  }, [t])

  const filteredDropdownItems = useMemo(() => {
    return allDropdownItems.filter((item) => item.name !== selectedItem)
  }, [selectedItem, allDropdownItems]);

  return (
    <div className="w-full h-full flex flew-row items-center justify-end">
      <Menu as="div" className="z-20 relative items-center mr-3">
        <MenuButton className="ml-1 items-center flex justify-center text-[15px] font-medium text-gray-600 bg-opacity-10 rounded-full hover:opacity-60 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
          <div className="flex grow text-right text-[15px] text-gray-900 font-bold my-auto">
            {allDropdownItems.find((item) => item.name === selectedItem)?.label}
          </div>
          <div className="ml-2 my-auto h-0 w-0 border-x-4 border-x-transparent border-t-[6px] border-gray-900" />
        </MenuButton>
        <MenuItems
          className="w-max absolute right-0 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-20"
          anchor="bottom end"
        >
          <ul className="px-2 text-[15px] text-right flex flex-col">
            {filteredDropdownItems.map((item, idx) => (
              <MenuItem key={idx}>
                {() => (
                  <li
                    key={idx}
                    className={classNames('py-1 px-1 text-right font-medium cursor-pointer hover:bg-gray-100', {
                      'cursor-pointer hover:bg-gray-100': item.enabled,
                      'text-gray-400 cursor-default': !item.enabled,
                    })}
                    onClick={() => (item.enabled ? setSelectedItem(item.name) : undefined)}
                  >
                    {item.label}
                  </li>
                )}
              </MenuItem>
            ))}
          </ul>
        </MenuItems>
      </Menu>
    </div>
  )
}
