import { useNavigate, useParams } from 'react-router-dom';
import { LoadingIndicator, SlideOver } from '@client/shared/toolkit';
import React, { useEffect, useState } from 'react';
import { ShortCommitmentReadModel, useApiGetContractQuery, ContractReadModel } from '@client/shared/api';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { useTranslation } from 'react-i18next';
import { ContractSlideOver, ContractEditSlideOver } from '@client/project/shared';

export type ContractViewType = 'details' | 'edit';

export const ContractView = ({ commitments, view = 'details', showLoading = true }: { commitments?: ShortCommitmentReadModel[] | null, view?: ContractViewType, showLoading?: boolean }) => {
  const { contractId } = useParams()

  const navigate = useNavigate();
  const { t } = useTranslation()

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const [openSlideOver, setOpenSlideOver] = useState<ContractViewType | null>(null);
  const [isChildSlideOverIsOpen, setIsChildSlideOverOpen] = useState(false);
  const [contract, setContract] = useState<ContractReadModel | null>(null);

  if (!contractId) {
    navigate(ROUTES_CONFIG.PROJECT_CONTRACT.name);
  }

  const { data: loadedContract, isFetching: isLoadingContract, isError } = useApiGetContractQuery(
    {
      contractId: contractId ?? '',
      projectId: loadedProjectId ?? '',
      calculationModelId: loadedVariantId ?? '',
    },
    {
      skip: !loadedProjectId || !loadedVariantId || !contractId || (!!contract && view === 'edit')
    },
  );

  useEffect(() => {
    if (typeof loadedContract !== 'undefined' && loadedContract !== null) {
      setContract(loadedContract);
      if (!contract) {
        setOpenSlideOver(view);
      }
    }
    if (isError) {
      navigate(ROUTES_CONFIG.PROJECT_CONTRACT.name);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedContract, isError, view]);


  return (
    <>
      {!openSlideOver && isLoadingContract && showLoading && (
        <LoadingIndicator text={t('projectContract.fetchingContractLoadingIndicator')} mode="overlay-window" />
      )}
      <SlideOver
        isOpen={!!openSlideOver}
        onClose={() => setOpenSlideOver(null)}
        variant={view === 'details' ? '2xl' : undefined}
        confirmBeforeClose={false}
        preventClickOutsideClose={isChildSlideOverIsOpen}
        onAfterLeave={() => {
          navigate(ROUTES_CONFIG.PROJECT_CONTRACT.name);
        }}
      >
        {openSlideOver && isLoadingContract && showLoading && (
          <LoadingIndicator text={t('projectContract.fetchingContractLoadingIndicator')} mode="overlay" />
        )}
        {view === 'details' && (
          <ContractSlideOver
            setChildSlideOverIsOpen={setIsChildSlideOverOpen}
            contractId={contractId ?? ''}
            selectedContract={contract}
            commitments={commitments ?? []}
            onClose={() => {
              setOpenSlideOver(null)
            }}
          />
        )}
        {view === 'edit' && (
          <ContractEditSlideOver
            contractId={contract?.id ?? ''}
            selectedContract={contract}
            commitments={commitments ?? []}
            onClose={(value) => {
              setOpenSlideOver(null)
            }}
          />
        )}
      </SlideOver>
    </>
  )
}
