import {useTranslation} from "react-i18next";
import {useMemo} from "react";
import {formatPercentage} from "@client/shared/utilities";
import classNames from "classnames";
import { FormattedCurrency } from '../FormattedCurrency';
import { DistributionValueType } from "./TimeLineMoneyDistributionModal";

interface TimeLineMoneyDistributionFooterProps {
  totalValue?: number;
  totalValueType?: DistributionValueType;
  isSaveDisabled?: boolean
  distributedAmount: number
}

export const TimeLineMoneyDistributionFooter = (props: TimeLineMoneyDistributionFooterProps) => {
  const { t } = useTranslation();
  const {
    totalValue,
    totalValueType,
    isSaveDisabled,
    distributedAmount
  } = props
  const restAmount = useMemo(() => {
    if (!totalValue || (totalValue.toFixed(2) === distributedAmount.toFixed(2))) return 0;
    return parseFloat((totalValue - distributedAmount).toFixed(2))
  }, [totalValue, distributedAmount]);

  const restPercentage = useMemo(() => {
    if (!totalValue) return 0;

    return parseFloat((restAmount / totalValue).toFixed(2));
  }, [restAmount, totalValue]);
  return (
    <>
      <div className="flex flex-row justify-end mr-4 pt-2 items-center">
        <div className="text-sm text-gray-500">
          {totalValueType === 'Forecast' ? t('projectCalculate.DistributionForecast') : t('projectCalculate.DistributionPlanned')}&nbsp;<FormattedCurrency amount={totalValue} options={{ maxDigits: 2 }} />
        </div>
        <div className="mx-1">{'•'}</div>
        <div className={classNames('text-sm text-gray-500 font-bold', { 'text-red-500': isSaveDisabled })}>
          {t('projectCalculate.DistributionRestValue')}&nbsp;<FormattedCurrency amount={restAmount} options={{ maxDigits: 2 }} />
        </div>
      </div>
      <div className="flex flex-row mr-4 justify-end items-end">
        {restPercentage !== 0 && (
          <div className="text-sm text-red-500 leading-none mr-1">
            {restPercentage > 0 ? '+' : null}
            {`${formatPercentage(restPercentage, { maxDigits: 2 })}`}
          </div>
        )}
        <div className="text-2xl leading-[18px] font-bold"><FormattedCurrency amount={distributedAmount} options={{ maxDigits: 2 }} /></div>
      </div>
    </>
  )
}
