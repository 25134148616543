import { Button } from '.';
import { Level1Icon, Level2Icon, Level3Icon } from '..';
import cn from 'classnames';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

interface LevelToggleProps {
  handleOnCollapse: (level: number) => void;
  showLevels?: number[];
  className?: string;
  variant?: 'primary' | 'secondary' | 'custom';
  buttonClassName?: string;
}

export const LevelToggle = ({
  handleOnCollapse,
  showLevels = [1, 2, 3],
  className,
  variant = 'secondary',
  buttonClassName,
}: LevelToggleProps) => {
  const { t } = useTranslation();
  if (!showLevels.length) return null;
  return (
    <div className={cn('flex items-center', className ? className : 'mx-4 gap-1 h-8')}>
      {showLevels.includes(1) && (
        <Button
          variant={variant}
          onClick={() => handleOnCollapse(1)}
          className={cn('flex-none', buttonClassName)}
          hasPadding={variant !== 'custom'}
        >
          <Level1Icon className="w-4 h-4" />
        </Button>
      )}
      {showLevels.includes(2) && (
        <Button
          variant={variant}
          onClick={() => handleOnCollapse(2)}
          className={cn('flex-none', buttonClassName)}
          hasPadding={variant !== 'custom'}
        >
          <Level2Icon className="w-4 h-4" />
        </Button>
      )}
      {showLevels.includes(3) && (
        <Button
          variant={variant}
          onClick={() => handleOnCollapse(3)}
          className={cn('flex-none', buttonClassName)}
          hasPadding={variant !== 'custom'}
        >
          <Level3Icon className="w-4 h-4" />
        </Button>
      )}
      {showLevels.length > 3 && (
        <Popover>
          {({ close }) => (
            <>
              <PopoverButton className="focus:outline-none flex items-center justify-center h-8 w-12 rounded-full font-medium transition-colors duration-200 focus:ring-2 focus:ring-offset-2 cursor-pointer bg-slate-100 hover:bg-slate-200 text-slate-500 flex-none">
                <ChevronDownIcon className="w-5 h-5" />
              </PopoverButton>
              <PopoverPanel
                portal
                transition
                anchor="bottom"
                className="mt-2.5 transition duration-200 ease-in-out data-[closed]:-translate-y-1 data-[closed]:opacity-0 z-20 flex items-center justify-center bg-gray-100 border border-gray-200 shadow-lg outline-none border-t-0 rounded-lg"
              >
                <div className="p-1.5 flex flex-col max-h-[240px]">
                  {showLevels.slice(3).map((level) => (
                    <Button
                      variant="custom"
                      className="p-1.5 font-normal text-sm text-slate-500 hover:text-slate-600 transition-colors duration-200"
                      onClick={() => {
                        close();
                        handleOnCollapse(level);
                      }}
                      key={`level-toggle-${level}`}
                      hasPadding={false}
                    >
                      {t('common.level')} {level}
                    </Button>
                  ))}
                </div>
              </PopoverPanel>
            </>
          )}
        </Popover>
      )}
    </div>
  );
};
