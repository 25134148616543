import {
  DistributionType,
  ElementTimelineReadModel
} from '@client/shared/api';
import { Modal } from '@client/shared/toolkit';
import React, { useState } from 'react';
import { DistributionValueType, TimeLineMoneyDistributionModal } from './TimeLineMoneyDistributionModal';
import { TimeLineDistributionSelect } from './TimeLineDistributionSelect';

interface TimeLineDistributionProps {
  timing?: ElementTimelineReadModel | null;
  variantId?: string;
  totalValue?: number;
  totalValueType?: DistributionValueType;
  onChange?: (timing: ElementTimelineReadModel) => void;
  hideEnd?: boolean;
  hideDistribution?: boolean;
  startLabel?: string;
  endLabel?: string;
  disabled?: boolean;
  disabledDistributionTypes?: DistributionType[];
  costGroupId?: string;
  riskGroupId?: string;
  clearable?: boolean;
  onClear?: () => void;
}

export const TimeLineDistribution = (props: TimeLineDistributionProps) => {
  const {
    hideEnd = false,
    totalValue,
    totalValueType,
    onChange,
    timing,
    variantId,
    disabled,
    disabledDistributionTypes,
    costGroupId,
    riskGroupId,
    onClear
  } = props
  const [showMoneyDistributions, setShowMoneyDistributions] = useState(false);
  return (
    <>
      <div
        onClick={() => {
          setShowMoneyDistributions(true);
        }}
        tabIndex={0}
        role="button"
      >
        <TimeLineDistributionSelect
          className="pointer-events-none"
          timeline={timing}
          disabledTypes={disabledDistributionTypes}
          disabled={disabled}
          onChange={() => {
            //
          }}
        />
      </div>

      {showMoneyDistributions && (
        <Modal
          isOpen={showMoneyDistributions}
          onClose={() => setShowMoneyDistributions(false)}
          variant="max"
        >
          <TimeLineMoneyDistributionModal
            hideEnd={hideEnd}
            disabled={disabled}
            timeline={timing}
            variantId={variantId}
            totalValue={totalValue}
            totalValueType={totalValueType}
            costGroupId={costGroupId}
            riskGroupId={riskGroupId}
            disabledDistributionTypes={disabledDistributionTypes}
            onClose={() => setShowMoneyDistributions(false)}
            onChange={onChange}
            onClear={onClear}
            onSave={() => {
              setShowMoneyDistributions(false);
            }}
          />
        </Modal>
      )}
    </>
  )
};
