import {
  ApiGetContractWithContractorReportApiResponse,
  useApiProjectGetUserDefinedFieldsDefinitionQuery,
} from '@client/shared/api';
import {
  //  AddFilledIcon,
  Button,
  CheckBox,
  ClearFiltersIcon,
  ConversionIcon,
  SearchInput,
} from '@client/shared/toolkit';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePopper } from 'react-popper';

interface ReportVariationSearchFilterProps {
  searchValue: string | undefined;
  setSearchValue: (value: string) => void;
  searchFilters: { contract: string[]; contractor: string[]; state: string[]; userDefinedField: string[] };
  setSearchFilters: (contract: string[], contractor: string[], state: string[], userDefinedField: string[]) => void;
  data: ApiGetContractWithContractorReportApiResponse | undefined;
  projectId: string | undefined | null;
}

export const ReportVariationSearchFilter = ({
  searchValue,
  setSearchValue,
  data,
  searchFilters,
  setSearchFilters,
  projectId,
}: ReportVariationSearchFilterProps) => {
  const { t } = useTranslation();
  const [targetElement, setTargetElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [selectedContracts, setSelectedContracts] = useState<string[]>([]);
  const [selectedContractors, setSelectedContractors] = useState<string[]>([]);
  const [selectedState, setSelectedState] = useState<string[]>([]);
  const [selectedUserDefinedField, setSelectedUserDefinedField] = useState<string[]>([]);

  const { data: userDefinedFieldData } = useApiProjectGetUserDefinedFieldsDefinitionQuery(
    {
      projectId: projectId ?? '',
    },
    { skip: !projectId },
  );

  useEffect(() => {
    setSelectedContracts(searchFilters.contract);
    setSelectedContractors(searchFilters.contractor);
    setSelectedState(searchFilters.state);
    setSelectedUserDefinedField(searchFilters.userDefinedField);
  }, [searchFilters]);

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-end'],
          rootBoundary: 'viewport',
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      },
    ],
  });

  const contractOptions = useMemo(() => {
    return (
      data?.map((contract) => ({
        id: contract.contractId ?? '',
        name: contract.contractName ?? '',
      })) ?? []
    );
  }, [data]);

  const contractorOptions = useMemo(() => {
    const options: { id: string; name: string; contractId: string }[] = [];
    data?.forEach((contract) => {
      if (
        !options.some((option) => option.id === contract.contractorId) &&
        contract.contractorName &&
        contract.contractorId
      ) {
        options.push({
          id: contract.contractorId,
          name: contract.contractorName,
          contractId: contract.contractId ?? '',
        });
      }
    });
    return options;
  }, [data]);

  const stateOptions = useMemo(() => {
    return [
      {
        value: 'Expected',
        label: t('projectContract.contractTitleStateExpected'),
        contracts:
          data
            ?.filter((contract) => contract.contractTitles.some((title) => title.state === 'Expected'))
            .map((contract) => contract.contractId ?? '') ?? [],
      },
      {
        value: 'Announced',
        label: t('projectContract.contractTitleStateAnnounced'),
        contracts:
          data
            ?.filter((contract) => contract.contractTitles.some((title) => title.state === 'Announced'))
            .map((contract) => contract.contractId ?? '') ?? [],
      },
      {
        value: 'Received',
        label: t('projectContract.contractTitleStateReceived'),
        contracts:
          data
            ?.filter((contract) => contract.contractTitles.some((title) => title.state === 'Received'))
            .map((contract) => contract.contractId ?? '') ?? [],
      },
      {
        value: 'Approved',
        label: t('projectContract.contractTitleStateApproved'),
        contracts:
          data
            ?.filter((contract) => contract.contractTitles.some((title) => title.state === 'Approved'))
            .map((contract) => contract.contractId ?? '') ?? [],
      },
      {
        value: 'Commissioned',
        label: t('projectContract.contractTitleStateCommissioned'),
        contracts:
          data
            ?.filter((contract) => contract.contractTitles.some((title) => title.state === 'Commissioned'))
            .map((contract) => contract.contractId ?? '') ?? [],
      },
      {
        value: 'Rejected',
        label: t('projectContract.contractTitleStateRejected'),
        contracts:
          data
            ?.filter((contract) => contract.contractTitles.some((title) => title.state === 'Rejected'))
            .map((contract) => contract.contractId ?? '') ?? [],
      },
      {
        value: 'Canceled',
        label: t('projectContract.contractTitleStateCanceled'),
        contracts:
          data
            ?.filter((contract) => contract.contractTitles.some((title) => title.state === 'Canceled'))
            .map((contract) => contract.contractId ?? '') ?? [],
      },
      {
        value: 'Budgeted',
        label: t('projectContract.contractTitleStateBudgeted'),
        contracts:
          data
            ?.filter((contract) => contract.contractTitles.some((title) => title.state === 'Budgeted'))
            .map((contract) => contract.contractId ?? '') ?? [],
      },
      {
        value: 'Optional',
        label: t('projectContract.contractTitleStateOptional'),
        contracts:
          data
            ?.filter((contract) => contract.contractTitles.some((title) => title.state === 'Optional'))
            .map((contract) => contract.contractId ?? '') ?? [],
      },
      {
        value: 'Reserve',
        label: t('projectContract.contractTitleStateReserve'),
        contracts:
          data
            ?.filter((contract) => contract.contractTitles.some((title) => title.state === 'Reserve'))
            .map((contract) => contract.contractId ?? '') ?? [],
      },
    ];
  }, [data, t]);

  const userDefinedFieldOptions: { id: string | null; name: string }[] = useMemo(() => {
    return (
      userDefinedFieldData?.userDefinedFieldsDefinition
        .filter((x) => x.elementType === 'Contract' || x.elementType === 'ContractTitle')
        .map((field) => {
          return {
            id: field.id,
            name: field.name,
          };
        }) ?? []
    );
  }, [userDefinedFieldData]);

  const resetToPrevious = () => {
    setSelectedContracts(searchFilters.contract);
    setSelectedContractors(searchFilters.contractor);
    setSelectedState(searchFilters.state);
    setSelectedUserDefinedField(searchFilters.userDefinedField);
  };

  const resetAll = () => {
    setSelectedContracts([]);
    setSelectedContractors([]);
    setSelectedState([]);
    setSelectedUserDefinedField([]);
  };

  const handleSetFilters = () => {
    setSearchFilters(selectedContracts, selectedContractors, selectedState, selectedUserDefinedField);
  };

  const searchFiltersActive =
    searchFilters.contract.length > 0 ||
    searchFilters.contractor.length > 0 ||
    searchFilters.state.length > 0 ||
    searchFilters.userDefinedField.length > 0;

  useEffect(() => {
    if (selectedContracts.length > 0) {
      selectedContractors.forEach((contractor) => {
        if (
          !selectedContracts.includes(data?.find((contract) => contract.contractorId === contractor)?.contractId ?? '')
        ) {
          setSelectedContractors(selectedContractors.filter((selectedContractor) => selectedContractor !== contractor));
        }
      });
      selectedState.forEach((state) => {
        if (
          !stateOptions
            .find((option) => option.value === state)
            ?.contracts.some((contract) => selectedContracts.includes(contract))
        ) {
          setSelectedState(selectedState.filter((selectedState) => selectedState !== state));
        }
      });
    } else {
      selectedState.forEach((state) => {
        if (
          stateOptions
            .find((option) => option.value === state)
            ?.contracts.every((contract) => {
              return data?.every((contract) => !contract.contractTitles.some((title) => title.state === state));
            })
        ) {
          setSelectedState(selectedState.filter((selectedState) => selectedState !== state));
        }
      });
    }
  }, [data, selectedContractors, selectedContracts, selectedState, stateOptions]);

  return (
    <div className="flex items-center">
      <div
        className={classNames(
          'text-sm font-medium text-gray-400',
          'bg-opacity-10 rounded-full overflow-hidden  gap-1',
          'flex justify-center',
        )}
      >
        <SearchInput
          value={searchValue}
          handleSearch={(val) => setSearchValue(val)}
          className="w-full h-full text-slate-500 px-6 py-2"
          bgColor="bg-gray-200 bg-opacity-40"
          variant="transparent"
        />

        <Popover>
          <>
            <PopoverButton
              ref={setTargetElement}
              className={classNames('h-full rounded-none pl-4 pr-6 py-2 focus:outline-none bg-gray-200 bg-opacity-50', {
                'bg-sky-200 bg-opacity-100': searchFiltersActive,
              })}
            >
              {searchFiltersActive ? (
                <ClearFiltersIcon className="h-5 w-5 text-gray-600" />
              ) : (
                <ConversionIcon className="h-5 w-5 text-gray-400" />
              )}
            </PopoverButton>

            <PopoverPanel
              as="div"
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              className="z-20  flex items-center justify-center bg-gray-100 border border-gray-200 shadow-lg outline-none border-t-0 rounded-lg "
            >
              {({ close }) => (
                <div className="w-full flex flex-col">
                  <div className="flex justify-end w-full pr-8 py-2">
                    <Button
                      className="rounded-none"
                      onClick={() => {
                        resetAll();
                      }}
                      variant="custom"
                    >
                      {selectedContracts.length > 0 || selectedContractors.length > 0 || selectedState.length > 0 ? (
                        <ClearFiltersIcon className="h-5 w-5" />
                      ) : (
                        <ConversionIcon className="h-5 w-5" />
                      )}
                    </Button>
                  </div>
                  <div className="h-full ml-6 mr-6 bg-white rounded-md flex flex-col divide-y text-black text-base font-normal  max-h-[400px] overflow-y-auto">
                    <div className="flex justify-between items-center p-2 font-bold">
                      {t('reporting.tableContract')}
                      <CheckBox
                        checked={contractOptions.every((contract) => selectedContracts.includes(contract.id))}
                        onChange={(val) => {
                          if (val) {
                            setSelectedContracts(contractOptions.map((option) => option.id));
                          } else {
                            setSelectedContracts([]);
                          }
                        }}
                      />
                    </div>
                    {contractOptions.map((option) => (
                      <div className="flex justify-between items-center p-2 pl-7" key={option.id}>
                        <span className="truncate w-11/12">{option.name}</span>
                        <CheckBox
                          className="w-1/12"
                          checked={selectedContracts.includes(option.id)}
                          onChange={(val) => {
                            if (val) {
                              setSelectedContracts([...selectedContracts, option.id]);
                            } else {
                              setSelectedContracts(selectedContracts.filter((contract) => contract !== option.id));
                            }
                          }}
                        />
                      </div>
                    ))}
                    {contractorOptions.length > 0 && (
                      <>
                        <div className="flex justify-between items-center p-2 font-bold">
                          {t('reporting.tableBranch')}
                          <CheckBox
                            checked={contractorOptions.every((contractor) =>
                              selectedContractors.includes(contractor.id ?? ''),
                            )}
                            onChange={(val) => {
                              if (val) {
                                setSelectedContractors(contractorOptions.map((option) => option.id ?? ''));
                              } else {
                                setSelectedContractors([]);
                              }
                            }}
                          />
                        </div>
                        {contractorOptions.map((option) => (
                          <div className="flex justify-between items-center p-2 pl-7" key={option.id}>
                            <span
                              className={classNames('truncate w-11/12', {
                                'text-gray-400':
                                  selectedContracts.length > 0 && !selectedContracts.includes(option.contractId),
                              })}
                            >
                              {option.name}
                            </span>
                            <CheckBox
                              className="w-1/12"
                              checked={selectedContractors.includes(option.id ?? '')}
                              onChange={(val) => {
                                if (val) {
                                  setSelectedContractors([...selectedContractors, option.id ?? '']);
                                } else {
                                  setSelectedContractors(
                                    selectedContractors.filter((contractor) => contractor !== option.id),
                                  );
                                }
                              }}
                              disabled={selectedContracts.length > 0 && !selectedContracts.includes(option.contractId)}
                            />
                          </div>
                        ))}
                      </>
                    )}
                    <div className="flex justify-between items-center p-2 font-bold">
                      {t('reporting.reportVariation.state')}
                      <CheckBox
                        checked={stateOptions.every((state) => selectedState.includes(state.value ?? ''))}
                        onChange={(val) => {
                          if (val) {
                            setSelectedState(stateOptions.map((option) => option.value ?? ''));
                          } else {
                            setSelectedState([]);
                          }
                        }}
                      />
                    </div>
                    {stateOptions.map((option, i) => (
                      <div className="flex justify-between items-center p-2 pl-7" key={`${option.value}-${i}`}>
                        <span
                          className={classNames('truncate w-11/12', {
                            'text-gray-400':
                              selectedContracts.length > 0
                                ? !option.contracts.some((contract) => selectedContracts.includes(contract))
                                : data?.every((contract) => !option.contracts.includes(contract.contractId ?? '')),
                          })}
                        >
                          {option.label}
                        </span>
                        <CheckBox
                          className="w-1/12"
                          checked={selectedState.includes(option.value)}
                          onChange={(val) => {
                            if (val) {
                              setSelectedState([...selectedState, option.value]);
                            } else {
                              setSelectedState(selectedState.filter((state) => state !== option.value));
                            }
                          }}
                          disabled={
                            selectedContracts.length > 0
                              ? !option.contracts.some((contract) => selectedContracts.includes(contract))
                              : data?.every((contract) => !option.contracts.includes(contract.contractId ?? ''))
                          }
                        />
                      </div>
                    ))}
                    {userDefinedFieldOptions.length > 0 && (
                      <>
                        <div className="flex justify-between items-center p-2 font-bold">
                          {t('common.userDefinedField')}
                          <CheckBox
                            checked={userDefinedFieldOptions.every((field) =>
                              selectedUserDefinedField.includes(field.id ?? ''),
                            )}
                            onChange={(val) => {
                              if (val) {
                                setSelectedUserDefinedField(userDefinedFieldOptions.map((option) => option.id ?? ''));
                              } else {
                                setSelectedUserDefinedField([]);
                              }
                            }}
                          />
                        </div>
                        {userDefinedFieldOptions.map((option) => (
                          <div className="flex justify-between items-center p-2 pl-7" key={option.id}>
                            <span className="truncate w-11/12">{option.name}</span>
                            <CheckBox
                              className="w-1/12"
                              checked={selectedUserDefinedField.includes(option.id ?? '')}
                              onChange={(val) => {
                                if (val) {
                                  setSelectedUserDefinedField([...selectedUserDefinedField, option.id ?? '']);
                                } else {
                                  setSelectedUserDefinedField(
                                    selectedUserDefinedField.filter((field) => field !== option.id),
                                  );
                                }
                              }}
                            />
                          </div>
                        ))}
                      </>
                    )}
                    {/* <button className="flex justify-between items-center p-2 bg-slate-200 hover:bg-slate-300 text-sky-900">
                      Filter erstellen <AddFilledIcon className="text-sky-900 h-6 -mr-4" />
                    </button> */}
                  </div>
                  <div className="w-full flex flex-col gap-0.5 h-fit-content px-6 pt-4 max-h-[400px] overflow-y-auto">
                    <div className="text-base bg-gray-100 bottom-0 flex gap-2 py-4 sticky w-full justify-end">
                      <Button
                        hasPadding={false}
                        className="bg-gray-300 text-white py-1.5 px-5"
                        variant="custom"
                        onClick={() => {
                          resetToPrevious();
                          close();
                        }}
                      >
                        {t('common.cancel')}
                      </Button>
                      <Button
                        hasPadding={false}
                        className="py-1.5 px-5"
                        variant="primary"
                        onClick={() => {
                          handleSetFilters();
                          close();
                        }}
                      >
                        {t('common.apply')}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </PopoverPanel>
          </>
        </Popover>
      </div>
    </div>
  );
};
