import { CalculateContext, TimeLineView } from ".";
import React, { PropsWithChildren, ReactNode, useContext } from "react";
import cn from "classnames";

export interface CalculateElementRowInformationColumnProps extends PropsWithChildren {
  className?: string;
  level: number
  view: TimeLineView
  handleOnClick?: (evt: React.MouseEvent<HTMLDivElement>) => void
  rightColumn?: ReactNode
  leftColumn?: ReactNode
  contentType?: 'group' | 'row'
  menu?: ReactNode
  isCollapsed?: boolean
}

/* Information Column for group elements */
export const CalculateElementRowInformationColumn = (props: CalculateElementRowInformationColumnProps) => {
  const {
    level,
    rightColumn,
    leftColumn,
    view,
    handleOnClick,
    menu
  } = props
  const { isListCollapsed } = useContext(CalculateContext)

  return (
    <div
      className={cn('flex w-full', {
        'cursor-pointer': handleOnClick
      })}
      onClick={handleOnClick}
    >
      <div className={view === TimeLineView.TIMELINE && !isListCollapsed ? 'w-2/3 md:w-1/2' : 'w-full flex-1'}>
        <div
          data-level={level}
          className="w-full pr-4 flex h-[38px] text-xs text-slate-600"
          style={{ paddingLeft: ((level - 1) * 10) + 32 }}
        >
          {leftColumn}
        </div>
      </div>
      {view === TimeLineView.TIMELINE && !isListCollapsed && (
        <div className="w-1/2 pr-6 lg:pr-0">
          <div className="w-full flex h-[38px] pr-4 truncate justify-end text-xs text-slate-600">
            {rightColumn}
          </div>
        </div>
      )}
      {menu}
    </div>
  )
}
