import {
  TimelineValueReadModel,
  ElementTimelineReadModel,
  useApiPostCalculateAutomaticTimelineDistributionMutation,
  useApiGetEffectiveTimelineDistributionMutation,
} from '@client/shared/api';
import { useEffect, useMemo, useRef, useState } from 'react';
import { formatDate, isEmpty } from '@client/shared/utilities';
import { Button, InitiateMoneyTransferIcon, LoadingIndicator, Modal, NumberInput, PercentageIcon, TimeLimitIcon } from '@client/shared/toolkit';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { useTranslation } from 'react-i18next';
import { FormattedCurrency } from '../FormattedCurrency';
import cn from 'classnames';

interface TimeLineDistributionsModalProps {
  totalValue?: number;
  timeline: ElementTimelineReadModel;
  // Effective distribution values are usually fetched,
  // but in some cases we can use them directly from distribution
  // because we don't need live data
  useExistingEffectiveDistributionValues?: boolean;
  costGroupId?: string;
  riskGroupId?: string;
  onClose: () => void;
  selectedDate?: string;
  isLoading?: boolean;
}

export const TimeLineDistributionsModal = ({
  costGroupId,
  onClose,
  riskGroupId,
  timeline,
  totalValue,
  useExistingEffectiveDistributionValues,
  selectedDate,
  isLoading = false
}: TimeLineDistributionsModalProps) => {
  const { t } = useTranslation();
  const loadedCalculationModelId = useLoadedVariantId();
  const loadedProjectId = useLoadedProjectId();

  const containerRef = useRef<HTMLDivElement>(null);
  const [distributionValues, setDistributionValues] = useState<TimelineValueReadModel[]>([]);

  const distributedAmount = distributionValues.reduce((x, y) => (x = x + y.value), 0);

  const [getMonthlyDistribution, {isLoading: isLoadingAutomaticDistribution}] = useApiPostCalculateAutomaticTimelineDistributionMutation();
  const [getEffectiveDistribution, {isLoading: isLoadingEffectiveDistribution}] = useApiGetEffectiveTimelineDistributionMutation();

  useEffect(() => {
    if (
      timeline.distribution?.type === 'Manual' &&
      timeline.distribution?.distributionValues &&
      !isEmpty(timeline.distribution.distributionValues)
    ) {
      setDistributionValues(timeline.distribution.distributionValues);
      return;
    }

    if (
      isEmpty(timeline.effectiveStartDate) ||
      isEmpty(timeline.effectiveEndDate) ||
      timeline.distribution?.type === 'Manual'
    ) {
      setDistributionValues([]);
      return;
    }

    const fetchMonthly = async ()=> {
      try {
        await getMonthlyDistribution({
          projectId: loadedProjectId ?? 'unset',
          calculationModelId: loadedCalculationModelId ?? 'unset',
          body: {
            costElementId: costGroupId,
            distributionType: timeline.distribution?.type ?? 'None',
            start: timeline.effectiveStartDate ?? '',
            end: timeline.effectiveEndDate ?? '',
            value: totalValue ?? 0,
            distributionFrequency: 'Month',
          },
        })
          .unwrap()
          .then((value) => {
            setDistributionValues(value ?? []);
          });
      } catch (error) {
        setDistributionValues([]);
        console.log(error);
      }
    };

    const fetchEffective = async () => {
      try {
        const values = await getEffectiveDistribution({
          projectId: loadedProjectId ?? 'unset',
          calculationModelId: loadedCalculationModelId ?? 'unset',
          costElementId: costGroupId,
          riskElementId: riskGroupId,
        }).unwrap();
        setDistributionValues(values ?? []);
      } catch (error) {
        setDistributionValues([]);
        console.log(error);
      }
    };

    if (timeline.distribution?.type === 'Constant' || timeline.distribution?.type === 'AllAtTheStart') {
      fetchMonthly();
      return;
    }

    if (timeline.distribution?.type === 'Effective') {
      if (useExistingEffectiveDistributionValues) {
        setDistributionValues(timeline.distribution.distributionValues);
      } else {
        fetchEffective();
      }
      return;
    }
  }, [
    loadedProjectId,
    loadedCalculationModelId,
    totalValue,
    timeline,
    getMonthlyDistribution,
    getEffectiveDistribution,
    costGroupId,
    riskGroupId,
    useExistingEffectiveDistributionValues,
  ]);

  const distributionTotalPercentageValue = totalValue !== undefined ? totalValue : distributedAmount;

  const activeRow = useMemo(() => {
    if (selectedDate) {
      return distributionValues.findIndex((distribution) => distribution.periodStart === selectedDate);
    }
    return null;
  }, [selectedDate, distributionValues]);

  useEffect(() => {
    if (containerRef?.current && activeRow && activeRow >= 0 && selectedDate) {
      const rowElem = containerRef.current.querySelector(`[data-date="${selectedDate}"]`);
      rowElem?.scrollIntoView({ block: 'center' });
    }
  }, [activeRow, selectedDate, containerRef]);

  return (
    <>
      <Modal.Header
        title={t('projectCalculate.ValueDistributionTitel')}
        description={t('projectCalculate.MonthlyDistributionModalDesc')}
      />
      <Modal.Content className="pb-3">
        {(isLoadingAutomaticDistribution || isLoadingEffectiveDistribution || isLoading) && (
          <LoadingIndicator text={t('common.loading')} mode="overlay-window" />
        )}
        <div className="flex flex-col overflow-y-auto mb-2" ref={containerRef}>
          {distributionValues.map((distributionValue, index) => (
            <div key={index} className="flex flex-row text-lg border-b">
              <div className={cn('flex flex-row w-5/12 border-r bg-white items-center', {
                'pl-3': activeRow !== index,
                'border-l-4 border-l-primary pl-2': activeRow === index
              })}>
                <TimeLimitIcon className="w-5 h-5" />
                <div className="flex-col pl-2 justify-center">
                  <div className="text-xs">{t('projectCalculate.DistributionDateColumn')}</div>
                  <span className="font-medium text-gray-500" data-date={distributionValue.periodStart}>
                    {formatDate(distributionValue.periodStart, { includeDay: false })}
                  </span>
                </div>
              </div>
              <div className="flex w-7/12">
                <NumberInput
                  className="w-full border-r"
                  disabled={true}
                  label={t('projectCalculate.DistributionPercentage')}
                  icon={<PercentageIcon />}
                  value={(distributionValue.value / distributionTotalPercentageValue) * 100}
                  onChange={() => {
                    /*Edit is currently disabled => ToDo: exchange with label that has editor layout*/
                  }}
                  decimalScale={3}
                />
                <NumberInput
                  className="w-full"
                  disabled={true}
                  label={t('projectCalculate.DistributionValueColumn')}
                  icon={<InitiateMoneyTransferIcon />}
                  value={distributionValue.value}
                  onChange={() => {
                    /*Edit is currently disabled => ToDo: exchange with label that has editor layout*/
                  }}
                  decimalScale={3}
                />
              </div>
            </div>
          ))}
        </div>

        <div className="flex flex-row mr-4 justify-end items-end">
          <div className="text-2xl font-bold">
            <FormattedCurrency amount={distributedAmount} options={{ maxDigits: 2 }} />
          </div>
        </div>
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button onClick={onClose} variant="secondary">
          {t('common.close')}
        </Button>
      </Modal.Controls>
    </>
  );
};
