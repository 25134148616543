import {
  apiBase, ApiTagTypes,
  InvoiceReadModel,
  useApiGetInvoiceWorkflowDetailQuery,
  useApiStartInvoiceWorkflowMutation,
} from '@client/shared/api';
import {
  DocumentViewerFileDataGroup,
  DocumentViewerFileDataSet,
  WorkflowIcon,
  WorkflowInfoText,
  WorkflowTaskInfo,
  WorkflowStep,
  LoadingIndicator,
  CircledPlayIcon,
  ContextMenu,
  ContextMenuItem,
} from '@client/shared/toolkit';
import { humanizeJsonDate, safeMutation, translateTaskName } from '@client/shared/utilities';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { StartWorkflowDisabledMessage } from '../StartWorkflowDisabledMessage';
import { useDispatch } from 'react-redux';

interface InvoiceWorkflowsProps {
  invoice: InvoiceReadModel;
}

export const InvoiceWorkflows = ({ invoice }: InvoiceWorkflowsProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState(false);

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const [startWorkflow, { isLoading: isStartingWorkflow }] = useApiStartInvoiceWorkflowMutation();
  const { data: workflow, isFetching: isLoadingWorkflow } = useApiGetInvoiceWorkflowDetailQuery(
    {
      invoiceId: invoice.id,
      projectId: loadedProjectId ?? '',
      calculationModelId: loadedVariantId ?? '',
    },
    {
      skip: !loadedProjectId || !loadedVariantId || !invoice.id || !invoice.hasWorkflow,
    },
  );

  const tasksHistory = useMemo(() => {
    return workflow?.tasksHistory ?? [];
  }, [workflow?.tasksHistory]);

  const futureTasks = useMemo(() => {
    return workflow?.futureTasks ?? [];
  }, [workflow?.futureTasks]);

  const [workflowStarted, setWorkflowStarted] = useState(false);

  useEffect(() => {
    if (workflowStarted && !workflow) {
      const interval = setInterval(() => {
        if (workflowStarted && workflow) {
          setWorkflowStarted(false);
          clearInterval(interval);
        } else {
          // @ts-expect-error tmp fix for missing callback from BE
          dispatch(apiBase.util.invalidateTags([{ type: ApiTagTypes.Invoices, id: loadedVariantId ?? '' }, ApiTagTypes.Contract, ApiTagTypes.Tasks, { type: ApiTagTypes.Workflow, id: invoice.id }, { type: ApiTagTypes.Invoice, id: invoice.id }]));
        }
      }, 10000);
      return () => clearInterval(interval);
    } else if (workflowStarted && workflow) {
      setWorkflowStarted(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowStarted, workflow]);

  const handleStartWorkflow = async (invoiceId: string) => {
    if (loadedProjectId && loadedVariantId) {
      try {
        setWorkflowStarted(true);
        await safeMutation(
          startWorkflow,
          {
            body: {
              projectId: loadedProjectId,
              calculationModelId: loadedVariantId,
              invoiceId: invoiceId,
            },
          },
          isStartingWorkflow,
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  const contextMenuItems: ContextMenuItem[] = [
    {
      label: invoice.startWorkflowStatus.canRestartWorkflow
        ? invoice.startWorkflowStatus.workflowName ? t('projectControl.restartWorkflowName', { name: invoice.startWorkflowStatus.workflowName }) : t('projectControl.restartWorkflow')
        : invoice.startWorkflowStatus.workflowName ? t('projectControl.startWorkflowName', { name: invoice.startWorkflowStatus.workflowName }) : t('projectControl.startWorkflow'),
      subtitle: t('projectControl.startWorkflowForInvoice'),
      icon: <CircledPlayIcon />,
      onClick: () => {
        handleStartWorkflow(invoice.id);
      },
      isDisabled: (!invoice.startWorkflowStatus?.canStartWorkflow && !invoice.startWorkflowStatus?.canRestartWorkflow) || (workflowStarted && !workflow),
      children: !invoice.startWorkflowStatus.canStartWorkflow && !invoice.startWorkflowStatus?.canRestartWorkflow ? (
        <StartWorkflowDisabledMessage invoice={invoice} projectId={loadedProjectId ?? ''} />
      ) : null,
      truncateText: false
    },
  ];

  return (
    <>
      {(isLoadingWorkflow || isStartingWorkflow) && (
        <LoadingIndicator text={t('projectControl.fetchingInvoiceLoadingIndicator')} mode="overlay-window" />
      )}
      <DocumentViewerFileDataGroup divider={false} className="relative">
        <div
          className={classNames('flex flex-col gap-y-8', {
            'cursor-pointer': tasksHistory?.length || futureTasks?.length,
          })}
          onClick={() => (tasksHistory?.length || futureTasks?.length) && setExpanded((prev) => !prev)}
        >
          {workflow ? (
            <div className="mb-2">
              <div className="flex gap-4 relative">
                <div
                  className={cn(
                    'w-7 relative',
                    "before:content-[''] before:min-h-[0.625rem] before:flex-none before:w-px before:h-full before:absolute before:top-0 before:left-1/2 before:-translate-x-1/2 before:bg-emerald-400 before:z-0",
                    {
                      'pb-3':
                        ((workflow.currentTask && !expanded) || (!workflow.currentTask && !expanded)) &&
                        tasksHistory.length,
                      'pb-8': expanded || (!tasksHistory.length && !expanded),
                    },
                  )}
                >
                  <div className="w-7 h-7 flex-none text-emerald-400 border-2 border-emerald-400 rounded-full flex items-center justify-center relative z-10 bg-white">
                    <WorkflowIcon className="w-4" />
                  </div>
                </div>
                <DocumentViewerFileDataSet
                  className={cn('flex-1 leading-none', {
                    'pb-3':
                      ((workflow.currentTask && !expanded) || (!workflow.currentTask && !expanded)) &&
                      tasksHistory.length,
                    'pb-8': expanded || (!tasksHistory.length && !expanded),
                    'hover:text-gray-500 duration-300 transition-colors': tasksHistory.length,
                  })}
                  label={t('app.settingsWorkflow')}
                  title={
                    <>
                      {workflow.workflowName}{' '}
                      {(tasksHistory.length > 0 || futureTasks.length > 0) && (
                        <ChevronDownIcon
                          className={classNames(
                            'transition-transform will-change-transform duration-100 transform -rotate-90 h5 w-5 flex-none inline-block -mt-0.5',
                            {
                              'rotate-0': expanded,
                            },
                          )}
                        />
                      )}
                    </>
                  }
                />
              </div>
              <div className="flex flex-col gap-0">
                {expanded &&
                  workflow.tasksHistory?.map((task, i) => (
                    <WorkflowStep key={'pastTask' + i} status="active">
                      <WorkflowTaskInfo
                        isLast={i === tasksHistory.length - 1 && !workflow.currentTask}
                        title={translateTaskName(task.taskName)}
                      >
                        <WorkflowInfoText label={`${t('projectControl.invoiceStatus')}:`} text={task.status} />
                        <WorkflowInfoText
                          label={t('app.settings.workflows.whoLabel')}
                          text={task.completedBy?.userName ?? '-'}
                        />
                        <WorkflowInfoText
                          label={t('app.settings.workflows.whenLabel')}
                          text={task.completedAt ? humanizeJsonDate(task.completedAt) : '-'}
                        />
                        <WorkflowInfoText label={`${t('projectControl.comment')}:`} text={task.comment ?? '-'} />
                      </WorkflowTaskInfo>
                    </WorkflowStep>
                  ))}

                {workflow.currentTask ? (
                  <>
                    {tasksHistory.length > 0 && !expanded && (
                      <WorkflowStep status="active">
                        <div className="h-8" />
                      </WorkflowStep>
                    )}
                    <WorkflowStep status="disabled">
                      <WorkflowTaskInfo
                        isLast={!expanded || !futureTasks?.length}
                        title={translateTaskName(workflow.currentTask.taskName)}
                        titleIcon
                      >
                        {workflow.currentTask.authorizedUsers && workflow.currentTask.authorizedUsers.length > 0 && (
                          <WorkflowInfoText
                            label={t('app.settings.workflows.whoLabel')}
                            text={workflow.currentTask.authorizedUsers.map((user) => user.userName).join(', ')}
                          />
                        )}
                      </WorkflowTaskInfo>
                    </WorkflowStep>
                    {workflow.currentTask.isLastTask && <WorkflowStep status="success-disabled" />}
                    {!workflow.currentTask.isLastTask && !expanded && <WorkflowStep status="disabled" />}
                    {expanded && futureTasks && futureTasks.length > 0 && (
                      <>
                        {futureTasks.map((task, i) => (
                          <WorkflowStep key={`future-task-${i}`} status="disabled">
                            <WorkflowTaskInfo title={translateTaskName(task.taskName)} />
                          </WorkflowStep>
                        ))}
                        <WorkflowStep status="success-disabled" />
                      </>
                    )}
                  </>
                ) : (
                  <WorkflowStep status="success" />
                )}
              </div>
            </div>
          ) : (
            <div>
              <div className="flex items-center gap-4">
                <div className="w-7">
                  <div className="w-7 h-7 flex-none text-emerald-400 border-2 border-slate-400 rounded-full flex items-center justify-center">
                    <WorkflowIcon className="w-4 text-slate-400" />
                  </div>
                </div>
                <DocumentViewerFileDataSet
                  className="text-slate-400 flex-1 leading-none"
                  label={t('app.settingsWorkflow')}
                  title={t(!workflow && workflowStarted ? 'projectControl.workflowStarted' :'app.settings.noWorkflowSelected')}
                />
               
              </div>
              {!workflow && workflowStarted && 
                <div className='flex justify-center p-6'>
                  <LoadingIndicator className="w-5 h-5" mode="inline" />
                </div>
              }
            </div>
          )}
        </div>
        <ContextMenu items={contextMenuItems} className="absolute right-4 top-6" />
      </DocumentViewerFileDataGroup>
    </>
  );
};
