import { InvoiceCalculationSchemeType } from '@client/shared/api';
import { i18n } from '@client/shared/utilities';

export function getInvoiceCoverSheetTypeLabel(type: InvoiceCalculationSchemeType) {
  switch (type) {
    case 'Cumulated':
      return i18n.t('app.settingsIcsTypeCumulated');
    case 'Single':
      return i18n.t('app.settingsIcsTypeNotCumulated');
    case 'AdvancePayment':
      return i18n.t('app.settingsIcsTypeAdvancePayment');
    default:
      return type;
  }
}

export function getInvoiceCoverSheetDeductionGroupLabel(type: string) {
  switch (type) {
    case 'Sum':
      return i18n.t('ics.deductionGroupLabelSum');
    case 'Custom':
      return type;
    case 'Payment':
      return i18n.t('ics.deductionGroupLabelPayment');
    default:
      return type;
  }
}

/**
 * Types + Translations:
 *
 * Zahlung, Payment
 * MwSt, VAT
 * Abschlagszahlung, Partial payment
 * Skonto, cash discount
 * Vorauszahlungstilgung (Tilgung), advance payment repayment (Repayment)
 * Nachlass, Discount
 * Kürzung, Deduction
 * Einbehalt, Retention
 * Zwischensumme, Subtotal
 */
export function getIcsDeductionTypeLabel(type: string) {
  switch (type) {
    case 'Payment':
      return i18n.t('ics.deductionTypePayment');
    case 'Deduction':
      return i18n.t('ics.deductionTypeDeduction');
    case 'Retention':
      return i18n.t('ics.deductionTypeRetention');
    case 'Subtotal':
      return i18n.t('ics.deductionTypeSubtotal');
    case 'Discount':
      return i18n.t('ics.deductionTypeDiscount');
    case 'Vat':
      return i18n.t('ics.deductionTypeVat');
    case 'Repayment':
      return i18n.t('ics.deductionTypeRepayment');
    case 'PartialPayment':
      return i18n.t('ics.deductionTypePartialPayment');
    case 'CashDiscount':
      return i18n.t('ics.deductionTypeCashDiscount');
    default:
      return type;
  }
}
export function getIcsDeductionReferenceTypeLabel(type: string) {
  switch (type) {
    case 'None':
      return i18n.t('ics.deductionReferenceTypeNone');
    case 'InvoiceValue':
      return i18n.t('ics.deductionReferenceTypeInvoiceValue');
    case 'PartialPayment':
      return i18n.t('ics.deductionReferenceTypePartialPayment');
    case 'Deduction':
      return i18n.t('ics.deductionReferenceTypDeduction');
    case 'DeductionGroup':
      return i18n.t('ics.deductionReferenceTypDeductionGroup');
    default:
      return type;
  }
}

export function getIcsDeductionName(name: string) {
  switch (name) {
    case 'Advance payment repayment':
      return i18n.t('ics.deductionNameAdvancePaymentRepayment');
    case 'Partial payment':
      return i18n.t('ics.deductionNamePartialPayment');
    case 'Vat':
      return i18n.t('ics.deductionNameVat');
    case 'Tax':
      return i18n.t('ics.deductionNameTax');
    case 'Cash discount':
      return i18n.t('ics.deductionNameCashDiscount');
    case 'Discount':
      return i18n.t('ics.deductionNameDiscount');
    case 'Sum after discount':
      return i18n.t('ics.deductionNameSumAfterDiscount');
    case 'Repayment':
      return i18n.t('ics.deductionNameRepayment');
    case 'Insurance retention':
      return i18n.t('ics.deductionNameInsuranceRetention');
    case 'Sum after retention':
      return i18n.t('ics.deductionNameSumAfterRetention');
    case 'Insurance apportionment':
      return i18n.t('ics.deductionNameInsuranceApportionment');
    case 'Completion retention':
      return i18n.t('ics.deductionNameCompletionRetention');
    default:
      return name;
  }
}