export enum ApiAdminTagTypes {
  AdminPublicApiAccess,
  AdminKlippaConfigurations,
  AdminLicenseGrants,
  AdminLicenses,
  AdminReport,
  AdminTenantProjects,
  AdminTenantUsers,
  AdminTenants,
  AdminWorkflows,
}
export const API_ADMIN_TAG_TYPES: string[] = Object.values(ApiAdminTagTypes).map((value) => value as string);
