import { useLoadedProjectId, useLoadedVariantId, useReadOnly } from '@client/project/store';
import { CostElementDto, TotalValues } from '@client/shared/api';
import { InitiateMoneyTransferIcon, Modal, SlideOver } from '@client/shared/toolkit';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CostCatalogElement, DecoratedElement } from '../hooks';
import { OptionalColumn, SectionTitle } from './CalculateSectionHeader';
import { CostElementSlideOver } from './CostElementSlideOver';
import { CostElementDeleteModal } from './CostElementDeleteModal';
import {
  useValidateCatalogHasRestrictions,
  useValidateProjectPermission
} from '@client/shared/permissions';
import { MIN_TIMELINE_HEADER_HEIGHT, TimeLineDataContext, TimeLineView } from './Timeline';
import { CalculateSectionHeaders } from './CalculateSectionHeaders';
import { CalculateSection } from './CalculateSection';
import {
  CalculateCostsRenderElements,
  CostElementDistributeObligoModal,
  CostElementTakeOverForecastModal,
} from './Costs';
import { EditCostsTimeLineMoneyDistributionModal, VatCreateModal } from './Distribution';
import { CalculateColumns, CalculateProps, CalculateTotal, NewElementModal, RegulatoryElementSlideOver } from '.';
import { CALCULATE_SECTION_PADDING, ELEMENT_ROW_HEIGHT } from '../utils';
import {
  CommitmentSlideOver,
  CommitmentTimeLineDistributionModal,
  ContractSlideOver,
  ContractTimeLineDistributionModal
} from '@client/project/shared';

const hasForecastChild = (children: DecoratedElement<CostCatalogElement>[]) => {
  if (children.length) {
    const foundForecastChild = children.find((child) => {
      return child.element.group?.modelValues.forecastValue || hasForecastChild(child.children);
      // if (child.element.type === 'group') {
      //   return child.element.group?.modelValues.effectiveForecast || hasForecastChild(child.children)
      // }
      // return child.element.costElement?.totalForecast
    });
    if (foundForecastChild) {
      return true;
    }
  }
  return false;
};

export interface CalculateCostsProps extends CalculateProps {
  elements?: DecoratedElement<CostCatalogElement>[];
  selectedVersionElements?: DecoratedElement<CostCatalogElement>[];
  setIsLoading: (loading: boolean) => void;
  totals: TotalValues | null | undefined;
  maxLevel: number;
}

export const CalculateCosts = (props: CalculateCostsProps) => {
  const {
    view,
    elements = [],
    variant,
    archivedVersions = [],
    selectedVersionId = '',
    setSelectedVersionId,
    optionalColumn,
    setOptionalColumn,
    obligoColumn,
    setObligoColumn,
    onCollapse,
    expandedCatalogIds = [],
    open = true,
    onCollapseContainer,
    total = 0,
    setIsLoading,
    searchValue,
    barChartData = false,
    totals,
    maxLevel
  } = props;
  const { t } = useTranslation();
  const loadedVariantId = useLoadedVariantId();
  const loadedProjectId = useLoadedProjectId();
  const disableCosts = !useValidateProjectPermission(['COSTS_WRITE'], loadedProjectId ?? '');
  const catalogHasRestrictions = useValidateCatalogHasRestrictions(
    loadedProjectId ?? '',
    variant?.modelMetadata.costCatalogId,
  );

  const isReadOnly = useReadOnly() || disableCosts;
  const { isHeaderCollapsed, headerHeight, setSelectedTimelineElement, selectedTimelineElement } =
    useContext(TimeLineDataContext);
  const [isOpenElementSlideOver, setIsOpenElementSlideOver] = useState(false);
  const [isOpenNewElementModal, setIsOpenNewElementModal] = useState(false);
  const [isOpenRegulatoryElementSlideOver, setIsOpenRegulatoryElementSlideOver] = useState(false);
  const [isOpenVatModal, setIsOpenVatModal] = useState(false);
  const [selectedRegulation, setSelectedRegulation] = useState<string | undefined>();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [elementId, setElementId] = useState<string | undefined | null>();
  const [deleteElementId, setDeleteElementId] = useState<string | undefined>();
  const [selectedCostElementCatalog, setSelectedCostElementCatalog] = useState<
    DecoratedElement<CostCatalogElement> | undefined
  >();
  const [selectedCostElement, setSelectedCostElement] = useState<CostElementDto | undefined>();
  const [preselectedGroupId, setPreselectedGroupId] = useState<string | undefined>();
  const [isOpenTakeOverModal, setIsOpenTakeOverModal] = useState(false);
  const [takeOverElementId, setTakeOverElementId] = useState<string | undefined>();
  const [takeOverElementChildren, setTakeOverElementChildren] = useState(false);
  const [isOpenDistributeModal, setIsOpenDistributeModal] = useState(false);
  const [distributeElementId, setDistributeElementId] = useState<string | undefined>();
  const [distributeElementChildren, setDistributeElementChildren] = useState(false);
  const [isMoneyDistributionModalOpen, setIsMoneyDistributionModalOpen] = useState(false);
  const [createCopy, setCreateCopy] = useState(false);

  // Edit contract / commitment
  const [selectedContractId, setSelectedContractId] = useState<string | null | undefined>(null);
  const [isOpenEditContractSlideOver, setIsOpenEditContractSlideOver] = useState(false);
  const [isChildSlideOverOpen, setIsChildSlideOverOpen] = useState(false);
  const [selectedCommitmentId, setSelectedCommitmentId] = useState<string | null | undefined>(null);
  const [isOpenEditCommitmentSlideOver, setIsOpenEditCommitmentSlideOver] = useState(false);
  const [isOpenTimeLineModal, setIsOpenTimeLineModal] = useState(false);

  const handleOpenSlideOver = (
    costElementId: string | null | undefined,
    costElement: CostElementDto | undefined,
    catalog: DecoratedElement<CostCatalogElement> | undefined,
    preselectedGroupId: string | undefined,
    contractId?: string | null,
    commitmentId?: string | null
  ) => {
    setSelectedCostElementCatalog(catalog);
    setElementId(costElementId);
    setSelectedCostElement(costElement);
    setPreselectedGroupId(preselectedGroupId);

    setSelectedContractId(contractId);
    setSelectedCommitmentId(commitmentId);

    if (contractId) {
      setIsOpenEditContractSlideOver(true);
    } else if (commitmentId) {
      setIsOpenEditCommitmentSlideOver(true);
    } else {
      setIsOpenElementSlideOver(true);
    }
  };

  const handleOpenRegulatoryElementSlideOver = (
    costElementId: string | null | undefined,
    catalog: string | undefined,
  ) => {
    setElementId(costElementId);
    setPreselectedGroupId(catalog);
    setIsOpenRegulatoryElementSlideOver(true);
  };

  const handleOpenNewElementModal = (catalog: string | undefined) => {
    setPreselectedGroupId(catalog);
    setIsOpenNewElementModal(true);
  };

  const handleCloseNewElementModal = () => {
    setElementId(undefined);
    setIsOpenNewElementModal(false);
  };

  const handleOpenDeleteElement = (deleteElementId?: string | null) => {
    if (!deleteElementId) {
      return;
    }

    setDeleteElementId(deleteElementId);
    setIsOpenDeleteModal(true);
  };

  const handleCloseSlideOver = () => {
    setIsOpenElementSlideOver(false);
    setIsOpenRegulatoryElementSlideOver(false);
  };

  const handleCloseDeleteElement = () => {
    setIsOpenDeleteModal(false);
    setDeleteElementId(undefined);
  };

  const handleCloseTakeOverModal = () => {
    setIsOpenTakeOverModal(false);
    setTakeOverElementId(undefined);
    setTakeOverElementChildren(false);
  };

  const handleCloseDistributeModal = () => {
    setIsOpenDistributeModal(false);
    setDistributeElementId(undefined);
    setDistributeElementChildren(false);
  };

  const handleCloseVatModal = () => {
    setSelectedRegulation(undefined);
    setIsOpenVatModal(false);
  };

  const handleSaveVatModal = () => {
    setIsOpenVatModal(false);
    setElementId(undefined);
  };

  const containerRef = useRef<HTMLDivElement>(null);

  // Show loading indicator as long not all expanded rows are visible
  useEffect(() => {
    if (!containerRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      if (containerRef.current) {
        if (containerRef.current.querySelectorAll('.cost-element').length >= total) {
          setTimeout(() => {
            setIsLoading(false);
          }, 200);
        }
      }
    });
    resizeObserver.observe(containerRef.current);
    return () => resizeObserver.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef.current, total, view]);

  const handleTakeOver = useCallback((element: DecoratedElement<CostCatalogElement>, children = false) => {
    if (
      element.element.type === 'group' &&
      element.element.group?.modelValues?.forecastValue &&
      element.element.group?.costElementId
    ) {
      if (element.element.type === 'group' && element.element.group?.costElementId) {
        setTakeOverElementId(element.element.group.costElementId);
      }
      setTakeOverElementChildren(false);
      if (children) {
        setTakeOverElementChildren(true);
      }
      setIsOpenTakeOverModal(true);
    }
  }, []);

  const showOnTakeOverAll = useCallback((element: DecoratedElement<CostCatalogElement>) => {
    if (element.children.length) {
      return hasForecastChild(element.children);
    }
    return false;
  }, []);

  // TODO
  // currently deactivated for all
  /* const showOnDistributeAll = useCallback((element: DecoratedElement<CostElement>) => {
    return false
  }, []) */

  // currently deactivated for all
  /* const showOnDistribute = useCallback((element: DecoratedElement<CostElement>) => {
    return !!(!element.element?.showReference &&
      (element.element?.costElement?.obligo || element.element?.group?.modelValues?.obligo) &&
      (element.element?.group?.costElementId || element.element?.costElement?.elementId));
  }, []) */

  /* const handleDistribute = useCallback((element: DecoratedElement<CostElement>, children = false) => {
    if (element.element.type === 'group' && element.element.group?.costElementId) {
      setDistributeElementId(element.element.group.costElementId);
    } else if (element?.element.costElement?.elementId) {
      setDistributeElementId(element.element.costElement.elementId);
    }
    setDistributeElementChildren(false)
    if (children) {
      setDistributeElementChildren(true)
    }
    setIsOpenDistributeModal(true);
  }, []) */

  // opens the corresponding distribution modal
  useEffect(() => {
    if (selectedTimelineElement?.elem?.elementId && selectedTimelineElement.elem?.elementType === 'costElement') {
      setIsMoneyDistributionModalOpen(true);
    } else if (selectedTimelineElement?.elem?.elementId && (selectedTimelineElement.elem?.elementType === 'commitment' || selectedTimelineElement.elem?.elementType === 'contract')) {
      setIsOpenTimeLineModal(true);
    }
  }, [selectedTimelineElement, setSelectedTimelineElement]);

  // resets the selected finance element
  useEffect(() => {
    if (!isMoneyDistributionModalOpen && setSelectedTimelineElement) {
      setSelectedTimelineElement(null);
    }
  }, [isMoneyDistributionModalOpen, setSelectedTimelineElement]);
  // resets the selected finance element
  useEffect(() => {
    if (!isOpenTimeLineModal && setSelectedTimelineElement) {
      setSelectedTimelineElement(null);
    }
  }, [isOpenTimeLineModal, setSelectedTimelineElement]);

  const height = useMemo(() => {
    const getHeight = (elements: DecoratedElement<CostCatalogElement>[], h: number, level: number) => {
      elements?.forEach((element) => {
        const open = expandedCatalogIds.includes(element.categoryId) || (!!searchValue && !!element.match);
        h += ELEMENT_ROW_HEIGHT + (level === 0 ? CALCULATE_SECTION_PADDING : 0);
        if (open) {
          // Finance view
          if (view === TimeLineView.FINANCE) {
            // Rest Budget
            if (
              !!element.element.group?.modelValues.restBudget &&
              element.element.group?.modelValues.restBudget !== element.element.group?.modelValues.effectiveValue
            ) {
              h += ELEMENT_ROW_HEIGHT;
            }
            // Commitments
            if (element.element.commitments?.length) {
              h += element.element.commitments.length * ELEMENT_ROW_HEIGHT;
            }
            // contracts
            if (element.element.contracts?.length) {
              h += element.element.contracts.length * ELEMENT_ROW_HEIGHT;
            }
            // Invoicing parties
            if (element.element.invoicingParties?.length) {
              h += element.element.invoicingParties.length * ELEMENT_ROW_HEIGHT;
            }
          }

          // Cost elements
          if (element.element?.costElements?.length) {
            h += element.element.costElements.length * ELEMENT_ROW_HEIGHT;

            if (view === TimeLineView.FINANCE) {
              element.element.costElements.forEach((elem) => {
                // cost element rest budget
                if (elem?.costElement?.restBudget && elem.costElement?.totalValue !== elem.costElement?.restBudget) {
                  h += ELEMENT_ROW_HEIGHT;
                }

                // cost element commitments
                if (elem.commitments?.length) {
                  h += elem.commitments.length * ELEMENT_ROW_HEIGHT;
                }
                // cost element contracts
                if (elem.contracts?.length) {
                  h += elem.contracts.length * ELEMENT_ROW_HEIGHT;
                }
                // cost element invoicing recipients
                if (elem.invoicingParties?.length) {
                  h += elem.invoicingParties.length * ELEMENT_ROW_HEIGHT;
                }
              });
            }
          }

          // Children
          if (element.children.length) {
            h = getHeight(element.children, h, level + 1);
          }
        }
      });

      return h;
    };

    return getHeight(elements, 0, 0);
  }, [expandedCatalogIds, searchValue, elements, view]);

  const [level, setLevel] = useState(1);
  const handleCollapse = useCallback(
    (level: number) => {
      setLevel(level);
      onCollapse(level);
    },
    [onCollapse],
  );

  const totalDict: Record<OptionalColumn, number | null | undefined> = {
    [OptionalColumn.CONTRACT]: totals?.actualContracts,
    [OptionalColumn.TOTAL_CONTRACT]: totals?.totalContracts,
    [OptionalColumn.PAYMENT]: totals?.payment,
    [OptionalColumn.ABSOLUTE_PAYMENT]: totals?.totalPayment,
    [OptionalColumn.OBLIGO]: totals?.obligo,
    [OptionalColumn.INVOICE]: totals?.invoice,
    [OptionalColumn.SUPPLEMENT]: totals?.supplement,
    [OptionalColumn.FORECAST]: totals?.forecast,
  };

  const totalSectionFinance = useMemo(() => {
    return (
      <div className="flex pl-2">
        <CalculateColumns
          view={view}
          currentColumnContent={
            <CalculateTotal
              catalogHasRestrictions={catalogHasRestrictions}
              totalText={t('projectCalculate.calculateCostsTotalSum')}
              totalCost={variant?.modelMetadata.totalCost.value}
              colorClass="text-costs"
            />
          }
          obligoColumnContent={
            <CalculateTotal
              catalogHasRestrictions={catalogHasRestrictions}
              totalText={t('projectCalculate.calculateCostsTotalSum')}
              totalCost={totalDict[obligoColumn] ?? 0}
              colorClass="text-costs"
            />
          }
          optionalColumnContent={
            <CalculateTotal
              catalogHasRestrictions={catalogHasRestrictions}
              totalText={t('projectCalculate.calculateCostsTotalSum')}
              totalCost={totalDict[optionalColumn] ?? 0}
              colorClass="text-costs"
            />
          }
        />
      </div>
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogHasRestrictions, obligoColumn, optionalColumn, t, variant?.modelMetadata.totalCost.value, view]);

  return (
    <div
      className="relative flex cursor-default transition-[margin] duration-300"
      style={{
        marginTop: !isHeaderCollapsed && headerHeight ? headerHeight - MIN_TIMELINE_HEADER_HEIGHT : undefined,
      }}
    >
      <div className="w-full" ref={containerRef}>
        <CalculateSectionHeaders
          view={view}
          archivedVersions={archivedVersions}
          selectedVersionId={selectedVersionId}
          setSelectedVersionId={setSelectedVersionId}
          optionalColumn={optionalColumn}
          setOptionalColumn={setOptionalColumn}
          obligoColumn={obligoColumn}
          setObligoColumn={setObligoColumn}
          title={SectionTitle.COSTS}
          icon={<InitiateMoneyTransferIcon className="w-7 h-7" />}
          onCollapse={handleCollapse}
          onCollapseContainer={() => onCollapseContainer(!open)}
          level={level}
          showLevelToggles={elements.length > 0 && !searchValue}
          showToggleButton
          maxLevel={maxLevel}
        />
        <CalculateSection
          catalogHasRestrictions={catalogHasRestrictions}
          open={open}
          view={view}
          totalSectionFinance={totalSectionFinance}
          totalCost={variant?.modelMetadata.totalCost.value}
          bgColor="bg-costs"
          totalColor="text-costs"
          totalText={t('projectCalculate.calculateCostsTotalSum')}
          hasBarChart={barChartData}
          height={elements.length > 0 ? height : undefined}
        >
          {elements.length > 0 ? (
            <CalculateCostsRenderElements
              elements={elements}
              containerRef={containerRef}
              handleOpenSlideOver={handleOpenSlideOver}
              handleOpenNewElementModal={handleOpenNewElementModal}
              handleOpenDeleteElement={handleOpenDeleteElement}
              level={level}
              showOnTakeOverAll={showOnTakeOverAll}
              handleTakeOver={handleTakeOver}
              handleOpenRegulatoryElementSlideOver={handleOpenRegulatoryElementSlideOver}
              setCreateCopy={setCreateCopy}
              isReadOnly={isReadOnly}
              {...props}
            />
          ) : (
            <div className="text-lg font-bold p-4 h-12">{t('projectCalculate.NoElements')}</div>
          )}
        </CalculateSection>
      </div>

      <SlideOver
        isOpen={isOpenElementSlideOver}
        onClose={handleCloseSlideOver}
        onAfterLeave={() => {
          setCreateCopy(false);
          setElementId(undefined);
          setSelectedCostElementCatalog(undefined);
        }}
      >
        <CostElementSlideOver
          variantId={loadedVariantId}
          elementId={elementId ?? ''}
          catalogElement={selectedCostElementCatalog?.element}
          catalogId={variant?.payload?.costCatalogId}
          disabled={isReadOnly}
          onClose={handleCloseSlideOver}
          timelineErrors={
            (selectedCostElement
              ? selectedCostElement?.timelineErrors
              : selectedCostElementCatalog?.element.timelineErrors) ?? []
          }
          contractBudgetErrors={
            (selectedCostElement
              ? selectedCostElement?.contractBudgetErrors
              : selectedCostElementCatalog?.element.contractBudgetErrors) ?? []
          }
          calculationState={selectedCostElementCatalog?.element.group?.modelValues.calculationState}
          preselectedGroupId={preselectedGroupId}
          createCopy={createCopy}
        />
      </SlideOver>

      <Modal isOpen={isOpenNewElementModal} onClose={() => setIsOpenNewElementModal(false)}>
        <NewElementModal
          catalogElementId={preselectedGroupId ?? ''}
          onClose={() => handleCloseNewElementModal()}
          onOpenSlideOver={(regulation: string) => {
            setIsOpenNewElementModal(false);
            if (regulation === 'vat') {
              setIsOpenVatModal(true);
            } else {
              setSelectedRegulation(regulation);
              setIsOpenRegulatoryElementSlideOver(true);
            }
          }}
        />
      </Modal>

      <SlideOver
        isOpen={isOpenRegulatoryElementSlideOver}
        onClose={handleCloseSlideOver}
        onAfterLeave={() => {
          setElementId(undefined);
        }}
      >
        <RegulatoryElementSlideOver
          variantId={loadedVariantId}
          catalogElementId={preselectedGroupId}
          elementId={elementId}
          regulation={selectedRegulation}
          disabled={isReadOnly}
          onClose={handleCloseSlideOver}
        />
      </SlideOver>

      <Modal isOpen={isOpenVatModal} onClose={handleCloseVatModal} variant="max">
        <VatCreateModal catalogId={preselectedGroupId} onClose={handleCloseVatModal} onSave={handleSaveVatModal} />
      </Modal>

      <Modal isOpen={isOpenDeleteModal} onClose={handleCloseDeleteElement}>
        <CostElementDeleteModal
          elementId={deleteElementId}
          variantId={loadedVariantId}
          onClose={handleCloseDeleteElement}
        />
      </Modal>

      <Modal isOpen={isOpenTakeOverModal} onClose={handleCloseTakeOverModal}>
        <CostElementTakeOverForecastModal
          elementId={takeOverElementId}
          variantId={loadedVariantId}
          onClose={handleCloseTakeOverModal}
          children={takeOverElementChildren}
        />
      </Modal>

      <Modal isOpen={isOpenDistributeModal} onClose={handleCloseDistributeModal}>
        <CostElementDistributeObligoModal
          elementId={distributeElementId}
          variantId={loadedVariantId}
          onClose={handleCloseDistributeModal}
          children={distributeElementChildren}
        />
      </Modal>

      {selectedTimelineElement?.elem?.elementId && selectedTimelineElement.elem?.elementType === 'costElement' && (
        <Modal
          isOpen={isMoneyDistributionModalOpen}
          onClose={() => setIsMoneyDistributionModalOpen(false)}
          variant="max"
        >
          <EditCostsTimeLineMoneyDistributionModal
            onClose={() => setIsMoneyDistributionModalOpen(false)}
            selectedElement={selectedTimelineElement.elem}
            costGroupId={
              selectedTimelineElement.elem.type === 'group' ? selectedTimelineElement.elem.elementId : undefined
            }
            onSave={() => {
              setIsMoneyDistributionModalOpen(false);
            }}
            disabled={disableCosts}
            selectedDate={selectedTimelineElement.date}
          />
        </Modal>
      )}

      {/* Edit contract */}
      <SlideOver
        isOpen={isOpenEditContractSlideOver}
        onClose={() => {
          setIsOpenEditContractSlideOver(false);
        }}
        preventClickOutsideClose={isChildSlideOverOpen}
        variant="2xl"
      >
        <ContractSlideOver
          setChildSlideOverIsOpen={setIsChildSlideOverOpen}
          contractId={selectedContractId ?? ''}
          onClose={() => {
            setIsOpenEditContractSlideOver(false);
          }}
        />
      </SlideOver>

      {/* Edit commitment */}
      <SlideOver
        isOpen={isOpenEditCommitmentSlideOver}
        onClose={() => setIsOpenEditCommitmentSlideOver(false)}
        variant="2xl"
        preventClickOutsideClose={isChildSlideOverOpen}
      >
        {selectedCommitmentId && (
          <CommitmentSlideOver
            setChildSlideOverIsOpen={setIsChildSlideOverOpen}
            commitmentId={selectedCommitmentId}
            onClose={() => {
              setIsOpenEditCommitmentSlideOver(false);
            }}
          />
        )}
      </SlideOver>

      {/* Commitment calculated timeline modal */}
      {selectedTimelineElement?.elem?.elementId && selectedTimelineElement.elem?.elementType === 'commitment' && (
        <Modal isOpen={isOpenTimeLineModal} onClose={() => setIsOpenTimeLineModal(false)}>
          <CommitmentTimeLineDistributionModal
            commitmentId={selectedTimelineElement?.elem?.elementId ?? undefined}
            selectedDate={selectedTimelineElement.date}
            onClose={() => setIsOpenTimeLineModal(false)}
          />
        </Modal>
      )}
      {selectedTimelineElement?.elem?.elementId && selectedTimelineElement.elem?.elementType === 'contract' && (
        <Modal isOpen={isOpenTimeLineModal} onClose={() => setIsOpenTimeLineModal(false)} variant="max">
          <ContractTimeLineDistributionModal
            contractId={selectedTimelineElement?.elem?.elementId}
            selectedDate={selectedTimelineElement.date}
            onClose={() => setIsOpenTimeLineModal(false)}
          />
        </Modal>
      )}
    </div>
  );
};
