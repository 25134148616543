type GenericElement = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export function getLevelledElements<T extends GenericElement>(
  data: T[],
  childrenKeys: string[],
  identifierKey: keyof T,
): { elementId: string; level: number; parentId: string | null; type: string; element: T }[] {
  const elements: { elementId: string; level: number; parentId: string | null; type: string; element: T }[] = [];

  const getLevel = (element: T, level: number, parentId: string | null, type: string) => {
    elements.push({ elementId: String(element[identifierKey]), level, parentId: parentId, type, element });

    childrenKeys.forEach((childrenKey) => {
      if (childrenKey in element) {
        if (element[childrenKey]) {
          (element[childrenKey] as T[]).forEach((child) =>
            getLevel(child, level + 1, element[identifierKey], childrenKey),
          );
        }
      }
    });
  };

  data.forEach((item) => getLevel(item, 0, null, 'childElements'));
  return elements;
}
