import { CommitmentDto } from '@client/shared/api';
import { formatPercentage } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';
import { Column } from './CalculateContainer';
import { OptionalColumn } from './CalculateSectionHeader';
import { CalculateElementRowInformationColumn } from './CalculateElementRowInformationColumn';
import { CalculateInformationColumnContent } from './CalculateGroupElementInformationColumn';
import { CalculateColumns } from './CalculateColumns';
import React from 'react';
import { TimeLineView } from './Timeline';
import { FormattedCurrency } from '@client/project/shared';
import { CalculateElementRowFinanceColumn } from './CalculateElementRowFinanceColumn';
import { CalculateSelectableColumn } from './CalculateSelectableColumn';
import cn from 'classnames';

interface CostCommitmentElementProps {
  commitment: CommitmentDto;
  level: number;
  optionalColumn: OptionalColumn;
  obligoColumn: OptionalColumn;
  index: number;
  budget: number;
  restBudget: boolean;
  top?: number;
  onClick?: () => void;
}

export const CostCommitmentElement = ({
  commitment,
  level,
  optionalColumn,
  obligoColumn,
  index,
  budget,
  restBudget,
  top = 0,
  onClick
}: CostCommitmentElementProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={cn(
        'commitment-element w-full flex group absolute left-0 bg-transparent',
        {
          'hover:bg-neutral-100 transition-colors duration-200 cursor-pointer': onClick
        }
      )}
      data-commitment={commitment.code}
      data-cost-element={commitment.costElementId || commitment.costElementId}
      style={{ top: top }}
      onClick={onClick}
      title={`${commitment.code} - ${commitment.name}`}
    >
      <CalculateColumns
        view={TimeLineView.FINANCE}
        informationColumnContent={
          <CalculateElementRowInformationColumn
            contentType="row"
            level={level}
            view={TimeLineView.FINANCE}
            leftColumn={
              <CalculateInformationColumnContent
                showCode={!!(commitment.code)}
                code={commitment.code}
                level={level}
                contentType="commitment"
                restBudget={restBudget}
                index={index}
                showExpanded
              >
                <div className="truncate flex flex-row items-center">
                  <span className="overflow-x-hidden text-ellipsis flex flex-row font-bold">
                    <div className="font-normal mr-1">{t('projectCalculate.commitment')}</div>
                    <div className="mr-1 truncate">{commitment.name}</div>
                    {(commitment.budgetValue !== 0) &&
                      budget !== 0 && (
                        <div className="text-xxs opacity-70">
                          {formatPercentage(
                            ((commitment.budgetValue) ?? 0) / budget,
                            { maxDigits: 0 }
                          )}
                        </div>
                      )}
                  </span>
                </div>
              </CalculateInformationColumnContent>
            }
            rightColumn={<>right column</>}
          />
        }
        currentColumnContent={
          <CalculateElementRowFinanceColumn
            level={level}
            columnType={Column.CURRENT}
            view={TimeLineView.FINANCE}
          >
            {!!commitment.budgetValue && <FormattedCurrency amount={commitment.budgetValue} />}
          </CalculateElementRowFinanceColumn>
        }
        obligoColumnContent={
          <CalculateSelectableColumn
            column={obligoColumn}
            level={level}
            columnType={Column.OBLIGO}
            view={TimeLineView.FINANCE}
            values={commitment}
            type="commitment"
          />
        }
        optionalColumnContent={
          <CalculateSelectableColumn
            column={optionalColumn}
            level={level}
            columnType={Column.OPTIONAL_COLUMN}
            view={TimeLineView.FINANCE}
            values={commitment}
            type="commitment"
          />
        }
      />
    </div>
  );
};
