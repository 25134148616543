import { TimeLineMoneyDistributionModal } from '../..';
import { useLoadedProject, useLoadedVariantId } from '@client/project/store';
import { ElementTimelineReadModel, useApiGetContractQuery } from '@client/shared/api';
import { ModalOnCloseProps } from '@client/shared/toolkit';
import React, { useEffect, useState } from 'react';
import { useValidateProjectPermission } from '@client/shared/permissions';

interface ContractTimeLineDistributionModalProps extends ModalOnCloseProps {
  contractId?: string;
  selectedDate?: string;
}

export const ContractTimeLineDistributionModal = ({
  contractId,
  selectedDate,
  onClose,
}: ContractTimeLineDistributionModalProps) => {
  const loadedProject = useLoadedProject();
  const loadedProjectId = loadedProject.currentData?.project.payload.id;
  const loadedVariantId = useLoadedVariantId();
  const valueType = loadedProject.currentData?.project.payload.calculateValueType;
  const canWrite = useValidateProjectPermission(['CONTRACT_WRITE'], loadedProjectId ?? '');
  const [elementTiming, setElementTiming] = useState<ElementTimelineReadModel | undefined | null>();
  const {
    data: contract,
    isFetching
  } = useApiGetContractQuery(
    {
      contractId: contractId ?? '',
      projectId: loadedProjectId ?? '',
      calculationModelId: loadedVariantId ?? '',
    },
    {
      skip: !loadedProjectId || !loadedVariantId || !contractId,
    },
  );

  useEffect(() => {
    setElementTiming(contract?.elementTimeline);
  }, [contract]);

  return (
    <TimeLineMoneyDistributionModal
      disabled={!canWrite || true} // TODO readonly for now as there needs to be created a separate endpoint to update the timeline only
      timeline={elementTiming}
      variantId={loadedVariantId}
      totalValue={valueType === 'Net' ? contract?.forecastValueNet ?? 0 : contract?.forecastValueGross ?? 0}
      totalValueType="Forecast"
      disabledDistributionTypes={['Effective']}
      onClose={() => onClose(false)}
      onChange={(value) => setElementTiming(value)}
      onClear={() => setElementTiming(null)}
      onSave={() => onClose(false)}
      isLoading={isFetching}
      selectedDate={selectedDate}
    />
  );
};
